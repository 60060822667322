import { Stack } from '@mui/material';
import { Calendar as RDRCalendar } from 'react-date-range/dist';
import * as rdrLocales from 'react-date-range/dist/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { esES } from '@mui/x-date-pickers/locales';
import { es } from 'date-fns/locale';
import { TimePicker } from '@mui/x-date-pickers';
import { Button } from '../Button';
import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog } from '../Dialog';
import { globalSnackbarVar } from '../../cache.reactiveVars';

type HiddenDatePickerProps = {
  value: Date | null;
  onChange: (date: Date) => void;
  onAccept?: () => void;
  includeTime?: boolean;
  minDate?: Date;
  open: boolean;
  onClose: () => void;
};

export const HiddenDatePicker: React.FC<HiddenDatePickerProps> = (props) => {
  const { open, onClose, minDate, includeTime, onChange, value } = props;
  const theme = useTheme();

  const [timeDate, setTimeDate] = React.useState<Date | null>(value);
  const [calendarDate, setCalendarDate] = React.useState<Date | null>(value);
  const [globalDate, setGlobalDate] = React.useState<Date | null>(value);

  const updateGlobalDate = (input: {
    year?: number;
    month?: number;
    day?: number;
    hour?: number;
    seconds?: number;
  }) => {
    const { year, month, day, hour, seconds } = input;
    const dateToSet = new Date(globalDate);
    if (day !== undefined) dateToSet.setDate(day);
    if (month !== undefined) dateToSet.setMonth(month);
    if (year !== undefined) dateToSet.setFullYear(year);
    if (hour !== undefined) dateToSet.setHours(hour);
    if (seconds !== undefined) dateToSet.setMinutes(seconds);

    if (minDate && dateToSet < minDate) {
      globalSnackbarVar({
        show: true,
        message: 'La fecha no puede ser anterior a la fecha actual',
        severity: 'error',
      });
      return false;
    }
    setGlobalDate(dateToSet);
    onChange(dateToSet);
  };

  const handleChangeCalendarDate = (calDate: Date) => {
    let dateToSet = new Date(calDate);
    setCalendarDate(dateToSet);
    updateGlobalDate({
      year: dateToSet.getFullYear(),
      month: dateToSet.getMonth(),
      day: dateToSet.getDate(),
    });
    if (!includeTime) {
      onClose();
    }
  };

  const onChangeTime = (time: Date) => {
    setTimeDate(time);
    updateGlobalDate({
      hour: time.getHours(),
      seconds: time.getMinutes(),
    });
  };

  useEffect(() => {
    const dateToSet = value ? new Date(value) : new Date();
    setTimeDate(dateToSet);
    setCalendarDate(dateToSet);
    setGlobalDate(dateToSet);
  }, [value]);

  return (
    <>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            mt: 1,
            width: '320px',
            borderRadius: '16px',
            boxShadow: theme.newPalette.shadow.dialog,
          },
        }}
        sx={{
          borderRadius: '10px',
        }}
      >
        <Stack maxHeight={'450px'}>
          <Stack>
            <RDRCalendar
              onChange={handleChangeCalendarDate}
              date={calendarDate}
              locale={rdrLocales.es}
              fixedHeight
              weekStartsOn={0}
              minDate={minDate}
            />
            {includeTime && (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                localeText={
                  esES.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                adapterLocale={es}
              >
                <Stack mx={1}>
                  <TimePicker
                    label="hora"
                    onAccept={onChangeTime}
                    onChange={onChangeTime}
                    value={timeDate}
                  />
                </Stack>
              </LocalizationProvider>
            )}
          </Stack>
          <Stack justifyContent="flex-end" flexDirection="row" my={2}>
            <Button onClick={onClose}>Cancelar</Button>
            <Button onClick={onClose}>OK</Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
