import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { Icon } from '../../../../components/Icons/Icons';
import { Typography } from '../../../../newComponents';

type AccordionProps = {
  icon: import('../../../../components/Icons/IconTypes').IconOptions;
  label: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  showAccordion?: boolean;
  chip?: boolean;
};

type ChipProps = React.PropsWithChildren<{}>;

const Chip: React.FC<ChipProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: theme.newPalette.primary.light,
        color: 'white',
        borderRadius: '12px',
        padding: '2px 4px',
        fontSize: '8px',
        fontWeight: 'bold',
        transform: 'translate(32px, -2px)',
        lineHeight: '10px',
      }}
    >
      {children}
    </Box>
  );
};

export const Accordion: React.FC<AccordionProps> = ({
  icon,
  label,
  children,
  style,
  showAccordion,
  chip,
}) => {
  const [expanded, setExpanded] = useState(
    showAccordion === undefined ? false : showAccordion,
  );
  const theme = useTheme();
  const palette = theme.customPalette;
  const color = expanded
    ? palette.status.inactive_gray
    : palette.textColor.text_black_light;
  return (
    <MUIAccordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      disableGutters
      sx={style ? style : accordionStyle}
    >
      <AccordionSummary expandIcon={<Icon icon={'arrow_down_s_line'} />}>
        <Stack gap={3} flexDirection="row" pl={1}>
          <Icon icon={icon} fill={palette.status.inactive_gray} height="20px" />
          <Stack sx={{ position: 'relative' }}>
            <Typography variant={'subtitle1'} color={color}>
              {label}
            </Typography>
            {chip && <Chip>BETA</Chip>}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{expanded && children}</AccordionDetails>
    </MUIAccordion>
  );
};

const accordionStyle = {
  '&.MuiAccordion-root': {
    border: '0px ',
    boxShadow: '0px 20px 40px -4px rgba(145, 158, 171, 0.16)',
    borderRadius: '8px',
  },
  '&.MuiAccordion-root:before': {
    backgroundColor: 'white',
  },
  minHeight: '55px',
};
