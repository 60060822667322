//@ts-check
import React, { useEffect } from 'react';
import { Calendar } from '../../../../newComponents/Datepicker';

type ValiditySelectorProps = {
  value: Date | null;
  label?: string;
  disabled?: boolean;
  includeTime?: boolean;
  size?: 'small' | 'medium';
  sx?: any;
  undefinedLabel?: string;
  placeholder?: string;
  dateFormat?: string;
  onChange: (date: Date) => void;
  onAccept?: () => void;
  iconPosition?: 'start' | 'end';
  minDate?: Date;
  fullWidth?: boolean;
  showIconToRemove?: boolean;
};

export const ValiditySelector: React.FC<ValiditySelectorProps> = ({
  value = null,
  label = 'Vigencia',
  disabled = false,
  includeTime = false,
  size,
  sx,
  undefinedLabel = 'Seleccionar',
  placeholder = 'Vigencia',
  dateFormat = 'd MMM yyyy',
  iconPosition,
  onChange,
  minDate = new Date(),
  fullWidth,
  showIconToRemove = false,
}) => {
  // the inner date is used to store the date while the user is selecting it in the calendar
  // using this approach we can control the shown date in the input field even if the consumer component
  // does not provide a value or handles the date change event separately from the value prop
  const [innerDate, setInnerDate] = React.useState<Date | null>(value);
  const updateDate = (date: Date) => {
    setInnerDate(date);
    onChange(date);
  };

  useEffect(() => {
    setInnerDate(value);
  }, [value]);

  return (
    <>
      <Calendar
        date={innerDate}
        setDate={(date) => {
          updateDate(date);
        }}
        formatDate={dateFormat}
        showIconToRemove={showIconToRemove ? Boolean(value) : false}
        includeTime={includeTime}
        textFieldProps={{
          disabled,
          size,
          sx,
          placeholder,
          fullWidth,
        }}
        showEndCalendarIcon={iconPosition === 'end'}
        showStartCalendarIcon={iconPosition === 'start'}
        label={label}
        undefinedLabel={undefinedLabel}
        minDate={minDate}
      />
    </>
  );
};
