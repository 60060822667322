import { useMutation, useApolloClient } from '@apollo/client';
import type { HandleUploadPendingDocumentInput } from '../RequestedEmployeeDocumentsDialog.types';
import {
  UPLOAD_PENDING_REQUESTED_EMPLOYEE_DOCUMENT,
  GET_REQUESTED_EMPLOYEE_DOCUMENTS,
} from '../RequestedEmployeeDocumentsDialog.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export const useUploadRequestedEmployeeDocument = () => {
  const client = useApolloClient();
  const [uploadPendingDocument] = useMutation(
    UPLOAD_PENDING_REQUESTED_EMPLOYEE_DOCUMENT,
  );

  const handleUploadPendingDocument = async (
    input: HandleUploadPendingDocumentInput,
  ) => {
    const {
      _id,
      file: acceptedFile,
      category,
      subcategory,
      successMessage = 'Archivo enviado al administrador',
      onSuccess,
      adminUploadDocument,
      uploadDocumentWithAi,
      responseAi,
    } = input;

    try {
      globalBackdropVar({ open: true, text: 'Subiendo documento...' });

      if (input.adminUploadDocument) {
        const { userId } = input;
        await uploadPendingDocument({
          variables: {
            file: acceptedFile,
            input: { _id, category, subcategory, adminUploadDocument, userId },
          },
        });
      } else {
        await uploadPendingDocument({
          variables: {
            file: acceptedFile,
            input: {
              _id,
              category,
              subcategory,
              uploadDocumentWithAi: Boolean(uploadDocumentWithAi),
              responseAi: responseAi,
            },
          },
        });
      }

      globalSnackbarVar({
        show: true,
        message: successMessage,
        severity: 'success',
      });

      globalBackdropVar({ open: false });

      onSuccess && onSuccess();

      await client.refetchQueries({
        include: [GET_REQUESTED_EMPLOYEE_DOCUMENTS],
      });
    } catch (error) {
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al subir documento',
        severity: 'error',
      });
    }
  };

  return { handleUploadPendingDocument };
};
