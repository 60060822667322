import React from 'react';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../../newComponents';

type AiAlertProps = {
  title: string;
  message: string;
};
export const AiAlert: React.FC<AiAlertProps> = ({ title, message }) => {
  const theme = useTheme();

  return (
    <>
      <Stack
        sx={{
          backgroundImage: theme.newPalette.primary.gradient,
          p: 2,
          borderRadius: 2,
          color: 'white',
        }}
        direction={'row'}
      >
        <Box sx={{ width: '100px' }}>
          <Icon
            icon={'bard_fill'}
            height={'24px'}
            width={'24px'}
            fill={'white'}
          />
        </Box>
        <Stack>
          <Typography color="white" variant={'h6'}>
            {title}
          </Typography>
          <Typography color="white" variant={'body2'}>
            {message}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};
