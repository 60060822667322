//@ts-check
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { PlainButton } from '../../../../components/Buttons/Buttons';
import { Icon } from '../../../../components/Icons/Icons';
import { H5, H6 } from '../../../../components/Typographies/Typography';
import { getHmtlFromCreator } from '../DocumentCreator/helper';
import { DocumentPreviewDialog } from '../DocumentCreator/DocumentPreviewDialog';
import { RenderEmployeeRecordType } from '../Tags/ExpandTags';
import { ScheduleForLabel } from './ScheduleForLabel';
import { formatDate } from '../../../../utils/utils';
import { Typography } from '../../../../newComponents/Typography';

export const ConfirmInfo = ({ form, docType }) => {
  /**
   * @type {import('../../../../theme').CustomTheme}
   */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const values = form.getValues();
  const scheduledFor = form.watch('scheduledFor');
  const [preview, setPreview] = useState(false);

  const createHtml = () => {
    const content = values.myEditor;
    const htmlPopulate = getHmtlFromCreator({
      data: values,
      preview,
      content,
    });
    return htmlPopulate;
  };

  return (
    <Stack
      alignItems="center"
      spacing={3}
      height={docType === 'creator' ? '630px' : '640px'}
    >
      <Stack>
        <Icon
          icon="information_line"
          fill={theme.customPalette.primary.blue}
          height="50px"
        />
        <H5>Revisa que todo este en orden</H5>
      </Stack>

      <Stack p={3} width={isMobile ? '100%' : '50%'}>
        <Stack
          direction={'row'}
          alignItems="center"
          justifyContent="space-between"
        >
          <H6>Detalle del envio</H6>
          {docType === 'creator' && (
            <PlainButton
              disableElevation
              endIcon={<Icon height="20px" icon="search_eye_line" />}
              sx={{
                border: `1px solid ${theme.customPalette.grayColors.gray_30}`,
                '&:disabled': {
                  backgroundColor: 'transparent',
                },
                height: '30px',
              }}
              onClick={() => setPreview(true)}
            >
              Previsualizar
            </PlainButton>
          )}
        </Stack>
        <Stack p={3} spacing={4}>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent="space-between"
            borderTop={`1px solid ${theme.customPalette.grayColors.gray_40}`}
          >
            <Typography variant={'body1'}>Tipo de documento</Typography>
            <Stack justifyContent="right" pt={1}>
              <RenderEmployeeRecordType
                params={{
                  row: {
                    id: 'documentCreator',
                    type: 'creador',
                    category: values?.categories?.category || '',
                    subcategory: values?.categories?.subcategory,
                  },
                }}
                handleOpen={() => {}}
                addStyle
                hideIconAiTag
              />
            </Stack>
          </Stack>
          {values.validity && (
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant={'body1'}>Vigencia</Typography>
              <Typography variant={'body1'}>
                {formatDate(values.validity)}
              </Typography>
            </Stack>
          )}
          {scheduledFor && (
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant={'body1'}>Envío programado para</Typography>
              <ScheduleForLabel
                value={scheduledFor}
                onDateChanged={(newDate) =>
                  form.setValue('scheduledFor', newDate)
                }
              />
            </Stack>
          )}
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant={'body1'}>Nombre del documento</Typography>
            <Typography variant={'body1'}>
              {values?.title || '-------'}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant={'body1'}>Documento firmable</Typography>
            <Typography variant={'body1'}>
              {values.signable ? 'Si' : 'No'}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent="space-between"
            borderBottom={`1px solid ${theme.customPalette.grayColors.gray_40}`}
            pb={2}
          >
            <Typography variant={'body1'}>Prioridad Alta</Typography>
            <Typography variant={'body1'}>
              {values.highPriority ? 'Si' : 'No'}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant={'body1'}>Empleados</Typography>
            <Typography variant={'body1'}>
              {!values?.sendAll ? values.signers?.length : 'Todos'}
            </Typography>
          </Stack>
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant={'body1'}>Firmantes de empresa</Typography>
            <Typography variant={'body1'}>
              {values.companySigners?.length}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {preview ? (
        <DocumentPreviewDialog
          open={preview}
          setOpen={setPreview}
          /* sendAll={sendAll}
          sendTo={sendTo}
          onSubmit={onSubmit} */
          createHtml={createHtml}
        />
      ) : null}
    </Stack>
  );
};
