import { createDocumentConterVar } from '../CreateDocumentDialog.vars';
import { useReactiveVar } from '@apollo/client';
import { DialogActions, Stack } from '@mui/material';
import {
  Button,
  ConfirmDialog,
  Typography,
} from '../../../../../newComponents';
import type {
  CreateDocumentActionButtonsProps,
  StepType,
} from '../CreateDocumentDialog.types';
import { useState } from 'react';
import { ConfirmSignersButton } from './ConfirmSignersButton';
import { SendDocumentButton } from './SendDocumentButton';
import { extractAndMapVariablesOptimized } from '../../../../../businessComponents/InsertVariablesButton/insertVariableButton.helpers';
import { globalBackdropVar } from '../../../../../cache.reactiveVars';
import { useScreenSize } from '../../../../../Hooks';

export const CreateDocumentActions = (
  props: CreateDocumentActionButtonsProps,
) => {
  const { form, onSubmit } = props;
  const { isMobile } = useScreenSize();
  const content = useReactiveVar(createDocumentConterVar);
  const [categories, title, myEditor, scheduledFor] = form.watch([
    'categories',
    'title',
    'myEditor',
    'scheduledFor',
  ]);

  const [openConfirmErrorDialog, setOpenConfirmErrorDialog] = useState(false);

  const category = categories?.category;
  const subcategory = categories?.subcategory;

  const templateEditorFormIsValid =
    !!title && !!myEditor && !!category && !!subcategory;

  const handleContent = (step: StepType) => {
    createDocumentConterVar(step);
  };

  const getVariablesFromEditor = () => {
    globalBackdropVar({ open: true, text: 'Extrayendo variables...' });
    const myEditor = form.getValues('myEditor');
    const vars = extractAndMapVariablesOptimized(myEditor);
    const uniqueVars = vars?.uniqueVariables ?? [];
    form.setValue('selectedVariables', uniqueVars);
    handleContent('SIGNERS');
    globalBackdropVar({ open: false });
  };

  const onSchedule = (date: Date) => {
    form.setValue('scheduledFor', date);
  };

  return (
    <DialogActions sx={{ p: '24px' }}>
      {content === 'EDITOR' && (
        <Button
          variant="contained"
          size={isMobile ? 'medium' : 'large'}
          disabled={!templateEditorFormIsValid}
          onClick={getVariablesFromEditor}
        >
          Continuar
        </Button>
      )}
      {content === 'SIGNERS' && (
        <ConfirmSignersButton form={form} handleContent={handleContent} />
      )}
      {content === 'VERIFY' && (
        <>
          <Button
            variant="outlined"
            size={isMobile ? 'medium' : 'large'}
            color="default"
            onClick={() => handleContent('SIGNERS')}
          >
            Atras
          </Button>
          <Button
            variant="contained"
            size={isMobile ? 'medium' : 'large'}
            onClick={() => setOpenConfirmErrorDialog(true)}
          >
            Ignorar errores y continuar
          </Button>
        </>
      )}
      {content === 'SETTINGS' && (
        <SendDocumentButton
          scheduledFor={scheduledFor}
          onSubmit={onSubmit}
          onSchedule={onSchedule}
          handleContent={handleContent}
        />
      )}

      <ConfirmDialog
        open={openConfirmErrorDialog}
        onClose={() => setOpenConfirmErrorDialog(false)}
        confirmLabel="Continuar"
        cancelLabel="Cancelar"
        onCancel={() => setOpenConfirmErrorDialog(false)}
        onConfirm={() => {
          handleContent('SETTINGS');
          setOpenConfirmErrorDialog(false);
        }}
        title="Ignorar errores"
        confirmButtonToRight
        handleCloseIconButton
        DialogProps={{
          fullScreen: false,
        }}
      >
        <Stack spacing={2}>
          <Typography variant="body1" color="text.secondary">
            Los usuarios con errores se eliminarán de la lista de asignados y no
            se les enviará ningún documento.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            ¿Deseas continuar?
          </Typography>
        </Stack>
      </ConfirmDialog>
    </DialogActions>
  );
};
