// @ts-check
import React from 'react';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { ListItem } from '../../../../../newComponents/ListItem';
import { ListItemIcon } from '../../../../../newComponents/ListItemIcon';
import { DOCUMENT_CATALOG } from '../hooks/useConfigureChecklistLogic';
import {
  StyledTableRow,
  TagNameCell,
  UploadDocForEmpCell,
  ActionCell,
} from '../DocumentChecklist/DocumentChecklist.styles';
import { RenderEmployeeRecordType } from '../../../../RHPod/Documents/Tags/ExpandTags';
import { DocumentCheckListAdminSelector } from '../DocumentChecklist/DocumentCheckListAdminSelector';
import { ADMIN_ACTIONS_CHECKLIST } from '../DocumentChecklist/DocumentChecklist.constants';

/**
 * @param {object} props
 * @param {import('../RecordChecklist.types').DocumentActiveTag} props.tag
 */
export const DocumentOverlay = ({ tag }) => {
  if (tag.listName === DOCUMENT_CATALOG) {
    return (
      <ListItem
        sx={{
          background: (theme) => theme.palette.background.paper,
          boxShadow: (theme) => theme.shadows[24],
          cursor: 'grabbing',
        }}
      >
        <ListItemIcon>
          <Checkbox color="primary" checked={tag.selected} />
        </ListItemIcon>
        <RenderEmployeeRecordType
          columnWidth={270}
          params={{
            row: {
              category: tag.documentCategoryName,
              subcategory: tag.documentSubcategoryName,
            },
          }}
          handleOpen={() => {}}
        />
      </ListItem>
    );
  }

  return (
    <StyledTableRow
      height="75px"
      sx={{
        background: (theme) => theme.palette.background.paper,
        boxShadow: (theme) => theme.shadows[24],
        cursor: 'grabbing',
      }}
    >
      <TagNameCell>
        <Checkbox color="success" checked={tag.selected} />
        <RenderEmployeeRecordType
          columnWidth={270}
          params={{
            row: {
              category: tag.documentCategoryName,
              subcategory: tag.documentSubcategoryName,
            },
          }}
          handleOpen={() => {}}
        />
      </TagNameCell>
      <UploadDocForEmpCell>
        <DocumentCheckListAdminSelector
          tag={tag}
          onChange={undefined}
          disabled={true}
          options={ADMIN_ACTIONS_CHECKLIST}
        />
      </UploadDocForEmpCell>
      <ActionCell></ActionCell>
    </StyledTableRow>
  );
};
