import { Stack } from '@mui/material';
import { Icon } from '../../../components/Icons/Icons';
import { Typography } from '../../../newComponents';
import { RenderEmployeeRecordType } from '../../../containers/RHPod/Documents/Tags/ExpandTags';

export const DocumentTitle = ({ onClose, file, category, subcategory }) => {
  return (
    <>
      <Icon
        color="warning"
        icon="ic_document"
        height="64px"
        pointer
        onClick={onClose}
      />
      <Typography
        variant="h6"
        sx={{ overflowWrap: 'break-word', width: '100%' }}
      >
        {file?.name}
      </Typography>
      <Stack gap="8px">
        <Typography variant="subtitle1">Tipo de documento</Typography>
        <RenderEmployeeRecordType
          params={{ row: { category, subcategory } }}
          handleOpen={() => {}}
          hideIconAiTag
        />
      </Stack>
    </>
  );
};
