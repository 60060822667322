// @ts-check
import { useEffect } from 'react';
import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { Button, IconButton } from '../../../../../newComponents';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { EmployeeRecordTagSubcategoryUpdateForm } from './EmployeeRecordTagSubcategoryUpdateForm';
import { DEFAULT_SUBCATEGORY_UPDATE_FORM } from '../EmployeeRecordTagsCatalog.constants';
import {
  useEmployeeRecordTagUpdate,
  useEmployeeRecordTagDeletion,
} from '../EmployeeRecordTagsCatalogHooks';
import {
  employeeRecordTagSubcategoryDeleteDialogVar,
  employeeRecordTagSubcategoryUpdateDialogVar,
  resetEmployeeRecordTagSubcategoryUpdateDialog,
} from '../EmployeeRecordTagsCatalog.vars';
import { AiAlert } from '../../../../../businessComponents/AiAlert/AiAlert';

export const EmployeeRecordTagSubcategoryUpdateDialog = () => {
  const { open, categoryId, subcategory, subcategoryIndex } = useReactiveVar(
    employeeRecordTagSubcategoryUpdateDialogVar,
  );

  const isAiEnabled = !!subcategory?.isAiEnabled;
  const { handleUpdateEmployeeRecordTagSubcategory } =
    useEmployeeRecordTagUpdate();

  const { handleDeleteEmployeeRecordTagSubcategory } =
    useEmployeeRecordTagDeletion();

  const form = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_SUBCATEGORY_UPDATE_FORM,
  });

  const formIsValid = form.formState.isValid;
  const formIsDirty = form.formState.isDirty;

  const handleClose = () => {
    resetEmployeeRecordTagSubcategoryUpdateDialog();
    form.reset();
  };

  const handleDeleteSubcategory = () => {
    if (!subcategory) return;
    handleDeleteEmployeeRecordTagSubcategory({
      categoryId,
      subcategoryId: subcategory._id,
      onSuccess: () => {
        handleClose();
      },
      onFail: (reason) => {
        employeeRecordTagSubcategoryDeleteDialogVar({
          open: true,
          categoryId,
          subcategory,
          failReason: reason,
        });
      },
    });
  };

  const handleUpdate = () => {
    let newIndex: number | null = subcategoryIndex;

    // if category changed, send index null to allocate
    // new data at the end of the new subcategory array
    if (form.getValues().categoryId !== categoryId) {
      newIndex = null;
    }

    handleUpdateEmployeeRecordTagSubcategory({
      categoryId: form.getValues().categoryId,
      subcategory: form.getValues(),
      newIndex,
      onSuccess: () => {
        handleClose();
      },
    });
  };

  useEffect(() => {
    if (!subcategory) return;
    form.reset({ ...subcategory, categoryId });
  }, [form, categoryId, subcategory]);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => handleClose()}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 3,
        }}
        component="div"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon
            color="grey"
            icon="close_line"
            height="26px"
            onClick={() => handleClose()}
            style={{ cursor: 'pointer' }}
          />
          Editar etiqueta
        </Stack>
        <IconButton
          icon="delete_bin_line"
          color="error"
          size="small"
          disabled={isAiEnabled}
          onClick={handleDeleteSubcategory}
          tooltipText={
            isAiEnabled ? 'No puedes eliminar esta subcategoria' : ''
          }
        />
      </DialogTitle>
      <DialogContent dividers>
        <EmployeeRecordTagSubcategoryUpdateForm form={form} />
        {isAiEnabled && (
          <Stack my={2}>
            <AiAlert
              title={'Este documento tiene integrado IA'}
              message={
                'Cuando el empleado cargue su documento, la plataforma lee los datos del documento para el uso de plantillas automatizadas, como creación de contrato, politicas, etc.'
              }
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          size="large"
          disabled={isAiEnabled || !formIsValid || !formIsDirty}
          onClick={form.handleSubmit(handleUpdate)}
          tooltipText={
            isAiEnabled ? 'No puedes modificar esta subcategoria' : undefined
          }
        >
          Guardar cambios
        </Button>
      </DialogActions>
    </Dialog>
  );
};
