import React from 'react';
import { Stack } from '@mui/material';
import { Button, Typography } from '../../../../../newComponents';
import { useScreenSize } from '../../../../../Hooks';

type CalendarHeaderProps = {
  openAddHolidayDialog: () => void;
};

export const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  openAddHolidayDialog,
}) => {
  const { isMobile } = useScreenSize();
  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      alignItems={'center'}
      spacing={2}
      justifyContent={'space-between'}
    >
      <Stack>
        <Typography variant="h6">Calendario</Typography>
        <Typography variant="body1">
          El calendario te ayuda a administrar los días festivos de tu empresa
        </Typography>
      </Stack>
      <Button
        onClick={() => openAddHolidayDialog()}
        fullWidth={isMobile}
        color={'primary'}
        variant={'contained'}
      >
        Agregar festivo
      </Button>
    </Stack>
  );
};
