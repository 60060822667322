import React, { memo } from 'react';
import { useTheme } from '@mui/material';
import {
  Checkbox,
  IconButton,
  ListItemIcon,
} from '../../../../../newComponents';
import { RenderEmployeeRecordType } from '../../../../RHPod/Documents/Tags/ExpandTags';

export const DocumentCatalogItem = memo(
  /** @param {import('./DocumentCatalog.types').DocumentCatalogItemProps} props */
  ({ tag, onRemoveTag, onSelectTag }) => {
    /** @type {import('../../../../../theme').CustomTheme} */
    const theme = useTheme();
    const isSelected = tag.selected || false;

    return (
      <React.Fragment>
        <ListItemIcon>
          <Checkbox
            color="primary"
            checked={isSelected}
            onChange={() => {
              onSelectTag(tag);
            }}
          />
        </ListItemIcon>
        <RenderEmployeeRecordType
          columnWidth={270}
          params={{
            row: {
              category: tag.documentCategoryName,
              subcategory: tag.documentSubcategoryName,
            },
          }}
          handleOpen={() => {}}
        />
        <ListItemIcon>
          <IconButton
            icon="add_line"
            color="success"
            size="small"
            sx={{
              visibility: 'hidden',
              backgroundColor: theme.newPalette.success.lighter,
              borderRadius: 2,
            }}
            onClick={() => onRemoveTag(tag)}
          />
        </ListItemIcon>
      </React.Fragment>
    );
  },
);
