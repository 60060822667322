import React from 'react';
import { TextInput } from '../../../../newComponents';

export const HolidaySchemeSelector: React.FC = () => {
  return (
    <TextInput
      size={'medium'}
      name="holidays_scheme"
      placeholder="Esquema actual"
      label="Esquema actual"
      value="Vacaciones de ley"
      sx={{ width: '300px' }}
      InputProps={{
        disabled: true,
      }}
    />
  );
};
