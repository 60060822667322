import React from 'react';
import { MenuItem, TextInput, Typography } from '../../../../../newComponents';
import { ADMIN_ACTIONS_CHECKLIST } from './DocumentChecklist.constants';

export const DocumentCheckListAdminSelector = ({
  label = 'Elegir documento',
  tag,
  onChange,
  disabled,
  options = ADMIN_ACTIONS_CHECKLIST,
}) => {
  return (
    <TextInput
      label={label}
      variant="outlined"
      fullWidth
      select
      value={tag?.documentAction}
      onChange={(event) => {
        onChange && onChange(tag, event.target.value);
      }}
      disabled={disabled}
      size="small"
      sx={{ width: { xs: '100%', sm: '100%', md: 300 } }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Typography variant="body2">{option.label}</Typography>
        </MenuItem>
      ))}
    </TextInput>
  );
};
