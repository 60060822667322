export enum CalendarEventTypes {
  // eslint-disable-next-line no-unused-vars
  OFFICIAL = 'OFFICIAL',
  // eslint-disable-next-line no-unused-vars
  COMPANY = 'COMPANY',
  // eslint-disable-next-line no-unused-vars
  SELECTION = 'SELECTION', // this is used for calendar selector
}

export type CalendarEvent = {
  _id: string;
  date: Date;
  title: string;
  type: CalendarEventTypes;
  color: string;
};

// eslint-disable-next-line no-unused-vars
export enum CalendarView {
  // eslint-disable-next-line no-unused-vars
  MONTH = 'MONTH',
  // eslint-disable-next-line no-unused-vars
  YEAR = 'YEAR',
}
