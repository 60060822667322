import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AuthorizationTeamRequiredFields,
  FormTriggerFieldsType,
  Steps,
} from './CreateHolidayAuthorizationTeamDialog.types';
import { CHAGGeneralForm } from './CHAGGeneralForm';
import { DialogActions, DialogContent } from '@mui/material';
import { Button } from '../../../../../../../newComponents';
import { CHAGAuthorizersForm } from './CHAGAuthorizersForm';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../../../cache.reactiveVars';
import { CHAGEmployeesForm } from './CHAGEmployeesForm';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  CREATE_AUTHORIZERS_TEAM,
  GET_HOLIDAY_AUTHORIZERS_TEAMS,
} from '../../../Holidays.gql';
import type { CreateHolidayAuthorizationTeamDialogFormType } from './CreateHolidayAuthorizationTeamDialog';
import type { AuthorizersTeam } from '../DisplayAuthorizersTeams.types';

// Definimos una interfaz para los mensajes de error
interface ErrorMessage {
  required: string;
}

type HolidayFormErrorMessages = Partial<
  Record<keyof AuthorizationTeamRequiredFields, ErrorMessage>
>;

export const createHolidayFormErrorMessages: HolidayFormErrorMessages = {
  name: { required: 'Ingresa un nombre' },
  authorizers: { required: 'Ingresa al menos un autorizador' },
};

type Props = {
  form: UseFormReturn<CreateHolidayAuthorizationTeamDialogFormType>;
  step: Steps;
  setStep: React.Dispatch<React.SetStateAction<Steps>>;
  onClose: () => void;
  preselectedTeam?: AuthorizersTeam;
};

const FormTriggerFields: FormTriggerFieldsType = {
  [Steps.General]: ['name', 'description'],
  [Steps.Authorizers]: ['authorizers'],
  [Steps.Employees]: ['name'],
};

export const CHAGForm: React.FC<Props> = ({
  form,
  step,
  setStep,
  onClose,
  preselectedTeam,
}) => {
  const {
    handleSubmit,
    trigger,
    formState: { errors },
  } = form;
  const [createAuthorizersTeam] = useMutation(CREATE_AUTHORIZERS_TEAM);
  const client = useApolloClient();
  const createLabel = preselectedTeam ? 'Actualizar' : 'Crear';
  const submitButtonLabel =
    step !== Steps.Employees ? 'Continuar' : createLabel;

  const onSubmit = async () => {
    const assignedTo = form.getValues('assignedTo');
    let employeeIds = assignedTo
      .filter((a) => a.type === 'Employee')
      .map((a) => a._id);
    let groups = assignedTo
      .filter((a) => a.type === 'Group')
      .map((g) => g.name);
    let workTitles = assignedTo
      .filter((a) => a.type === 'WorkTitle')
      .map((w) => w.name);
    let workCenterIds = assignedTo
      .filter((a) => a.type === 'WorkCenter')
      .map((w) => w._id);
    let sendToAllEmployees = assignedTo.some((a) => a.type === 'ALL_EMPLOYEES');
    if (sendToAllEmployees) {
      employeeIds = [];
      groups = [];
      workTitles = [];
      workCenterIds = [];
    }
    try {
      globalBackdropVar({ open: true, clickable: false, text: 'Creando...' });
      const { data } = await createAuthorizersTeam({
        variables: {
          input: {
            name: form.getValues('name'),
            description: form.getValues('description'),
            color: form.getValues('color'),
            authorizersIds: form.getValues('authorizers').map((a) => a._id),
            signInOrder: form.getValues('signInOrder'),
            employeeIds: employeeIds,
            groups: groups,
            workTitles: workTitles,
            workCenterIds: workCenterIds,
            sendToAllEmployees: sendToAllEmployees,
          },
        },
      });
      if (data.createAuthorizersTeam.__typename === 'Success') {
        globalSnackbarVar({
          message: 'Grupo de autorizadores creado con éxito',
          severity: 'success',
          show: true,
        });
      } else {
        console.error(data.createAuthorizersTeam);
        globalSnackbarVar({
          message: 'Hubo un error. Contacte a soporte.',
          severity: 'error',
          show: true,
        });
      }
      await client.refetchQueries({ include: [GET_HOLIDAY_AUTHORIZERS_TEAMS] });
      onClose();
    } catch (error) {
      globalSnackbarVar({
        message: 'Hubo un error. Contacte a soporte.',
        severity: 'error',
        show: true,
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  const onError = (errorFields) => {
    const { assignedTo, assignedToErrors } = errorFields;
    if (assignedTo) {
      globalSnackbarVar({
        message: assignedTo.message,
        severity: 'warning',
        show: true,
      });
    }
    if (assignedToErrors) {
      globalSnackbarVar({
        message: assignedToErrors.message,
        severity: 'warning',
        show: true,
      });
    }
  };

  const handleNext = async () => {
    const stepFields = FormTriggerFields[step];
    const validation = await trigger(stepFields);
    if (!validation) {
      const errorMessages = stepFields.reduce((acc, field) => {
        if (!errors[field]) return acc;
        return `${acc} ${createHolidayFormErrorMessages[field].required}.`;
      }, '');
      return globalSnackbarVar({
        message: errorMessages,
        severity: 'warning',
        show: true,
      });
    }
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  return (
    <>
      <DialogContent>
        {step === Steps.General && <CHAGGeneralForm form={form} />}
        {step === Steps.Authorizers && <CHAGAuthorizersForm form={form} />}
        {step === Steps.Employees && <CHAGEmployeesForm form={form} />}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={step === Steps.General ? onClose : handlePrev}
          color="primary"
        >
          {step === Steps.General ? 'Cancelar' : 'Atrás'}
        </Button>
        <Button
          onClick={
            step === Steps.Employees
              ? handleSubmit(onSubmit, onError)
              : handleNext
          }
          color="primary"
        >
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </>
  );
};
