import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';

import { CONTACT_METHODS, SEND_RECOVERY } from '../../../../Login/gql';
import {
  dialogOpenVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import {
  resetSendRecoveryDialogVar,
  sendRecoveryDialogVar,
} from '../../Employees.vars';
import { useEffect } from 'react';
import { Dialog } from '../../../../../newComponents';
import { loggerUtil } from '../../../../../utils/loggerUtil';
import { SelectOptionDialog } from './SelectOptionDialog';
import { openEmployeeInfoDialog } from '../../Employees.utils';

export const SendRecoveryDialog = ({ isFromSupport = false }) => {
  const { open, employeeId } = useReactiveVar(sendRecoveryDialogVar);

  const [getContactMethods, { data, loading, error }] =
    useLazyQuery(CONTACT_METHODS);

  const hasError =
    data?.sifeContactMethods?.__typename === 'ResourceNotFound' || error;

  const hasNoContactMethods =
    data?.sifeContactMethods?.email === null &&
    data?.sifeContactMethods?.phone === null;

  useEffect(() => {
    if (open) {
      getContactMethods({
        variables: {
          input: {
            _id: employeeId,
          },
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [open, getContactMethods, employeeId]);

  const [sendRecovery] = useMutation(SEND_RECOVERY);

  const handleSendRecovery = async (contactMethod) => {
    dialogOpenVar({ ...dialogOpenVar(), sendRecovery: false });
    if (!contactMethod) return;
    try {
      globalBackdropVar({
        open: true,
        text: 'Enviando link de recuperación...',
      });

      const result = await sendRecovery({
        variables: {
          input: {
            contactMethod,
            _id: employeeId,
          },
        },
      });

      if (result.data?.sifeSendRecovery?.__typename === 'Success') {
        globalSnackbarVar({
          show: true,
          message: 'Link de recuperación enviado',
          severity: 'success',
        });
      } else {
        globalSnackbarVar({
          show: true,
          message: result.data?.sifeSendRecovery?.message,
          severity: 'error',
        });
      }
    } catch (err) {
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        message: 'No pudimos enviar tu link de recuperación',
        severity: 'error',
      });
      loggerUtil.error(err);
    } finally {
      globalBackdropVar({ open: false });
      resetSendRecoveryDialogVar();
    }
  };

  const handleOpenEmployeeInfo = () => {
    resetSendRecoveryDialogVar();
    openEmployeeInfoDialog({ employeeId });
  };

  const handleConfirm = async (contactMethod) => {
    if (hasNoContactMethods) {
      handleOpenEmployeeInfo();
      return;
    }
    await handleSendRecovery(contactMethod);
  };

  const title = hasNoContactMethods
    ? 'Agregar método de contacto'
    : 'Recuperación de contraseña';

  let content = '';
  if (hasNoContactMethods && !isFromSupport) {
    content =
      "Da click en 'Aceptar' para ir al expediente del usuario y agregar un metodo de contacto.";
  }

  if (!hasNoContactMethods) {
    content = '¿A donde quieres que enviemos el link de recuperación?';
  }

  let contactMethods = [];

  if (!hasNoContactMethods) {
    if (data?.sifeContactMethods?.email) {
      contactMethods.push({
        value: 'email',
        label: data?.sifeContactMethods.email || '',
      });
    }

    if (data?.sifeContactMethods?.phone) {
      contactMethods.push({
        value: 'phone',
        label: data?.sifeContactMethods.phone || '',
      });
    }
  }

  return (
    <Dialog open={open} onClose={resetSendRecoveryDialogVar}>
      <SelectOptionDialog
        title={title}
        content={content}
        open={open}
        onClose={resetSendRecoveryDialogVar}
        onConfirm={handleConfirm}
        loading={loading}
        error={hasError}
        options={contactMethods}
      />
    </Dialog>
  );
};
