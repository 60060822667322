// @ts-check
import React, { memo } from 'react';
import { Stack, Divider, useTheme, useMediaQuery } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { Icon } from '../../../../../components/Icons/Icons';
import { Avatar } from '../../../../../newComponents/Avatar';

export const DocumentCatalogHeaderInfo = memo(
  /** @param {import('./DocumentCatalog.types').DocumentCatalogHeaderInfoProps} props */
  (props) => {
    /** @type {import('../../../../../theme').CustomTheme} */
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
      <Stack>
        <Stack
          p={3}
          gap={2}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          <Stack direction="row" gap={2}>
            {!isSmallScreen && (
              <Avatar>
                <Icon icon="folders_line" width="24px" height="24px" />
              </Avatar>
            )}
            <Stack>
              <Typography variant="subtitle2">
                Catálogo de documentos
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Estos son los documentos y categorías de tu catálogo.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
    );
  },
);
