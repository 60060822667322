import { makeVar } from '@apollo/client';
import type { ConfirmPersonalDataDialogVarType } from './ConfirmPersonalDataAi.types';

export const confirmPersonalDataDialogDefault: ConfirmPersonalDataDialogVarType =
  {
    open: false,
    onClose: () => {},
    formType: '',
    imssResponse: null,
    satResponse: null,
    documentData: null,
    error: null,
  };

export const confirmPersonalDataDialogVar = makeVar(
  confirmPersonalDataDialogDefault,
);

export const resetConfirmPersonalDataDialog = () => {
  confirmPersonalDataDialogVar(confirmPersonalDataDialogDefault);
};

export const setConfirmPersonalDataDialogVar = (
  input: Partial<ConfirmPersonalDataDialogVarType>,
) => {
  confirmPersonalDataDialogVar({
    ...confirmPersonalDataDialogVar(),
    ...input,
  });
};
