import type {
  AddressDataType,
  ErrorImssResponse,
  ErrorSatResponse,
  ErrorTypeAIMessage,
  NssDataType,
  PersonalDataType,
  PersonalOnboardingInfoType,
} from './ConfirmPersonalDataAi.types';

// Constantes con tipos asignados
export const PERSONAL_ONBOARDING_INFO_TYPE: PersonalOnboardingInfoType = {
  BIRTHDATE: 'BIRTHDATE',
  GENDER: 'GENDER',
  MARITAL_STATUS: 'MARITAL_STATUS',
  ADDRESS: 'ADDRESS',
};

export const DEFAULT_ONBOARDING_PERSONAL_DATA: PersonalDataType = {
  birthdate: null,
  gender: '',
  maritalStatus: '',
};

export const DEFAULT_ONBOARDING_ADDRESS_DATA: AddressDataType = {
  country: 'MEX',
  zipCode: '',
  state: '',
  city: '',
  colony: '',
  street: '',
  externalNumber: '',
  internalNumber: '',
};

export const DEFAULT_ONBOARDING_NSS_DATA: NssDataType = {
  socialSecurityNumber: '',
};

export const ERROR_TYPE_AI_MESSAGE: ErrorTypeAIMessage = {
  IMAGE_IS_TO_BLURRED:
    'El archivo está borroso, legible, o no cumple el formato',
  SUCCESS: 'Archivo verificado con éxito',
  BAD_REQUEST: 'El archivo proporcionado no es válido',
  ACCESS_DENIED: 'No fue posible acceder a la información del archivo',
  FILE_NOT_FOUND: 'No pudimos obtener el archivo a verificar',
  INVALID_FILE_EXTENSION: 'Extensión del archivo inválida',
};

export const ERROR_SAT_RESPONSE: ErrorSatResponse = {
  ...ERROR_TYPE_AI_MESSAGE,
  CONFLICT: 'El archivo no es una constancia de situación fiscal',
  MISMATCH: 'Los datos del archivo no coinciden con los del SAT',
  OCR_ONLY: 'El archivo no pudo ser validado por el SAT',
};

export const ERROR_IMSS_RESPONSE: ErrorImssResponse = {
  ...ERROR_TYPE_AI_MESSAGE,
  NSS_COULD_NOT_BE_VALIDATED: 'El número de seguro social no se encontró',
};

export const ERROR_FORM_REQUIRED = {
  type: 'required',
  message: 'Campo requerido',
};
