import { makeVar } from '@apollo/client';
import type {
  EmployeeRecordTagCategory,
  EmployeeRecordTagSubcategory,
  DeleteEmployeeRecordTagSubcategoryFailReason,
} from './EmployeeRecordTagsCatalog.types';

export const employeeRecordTagsVar = makeVar<EmployeeRecordTagCategory[]>([]);
export const employeeRecordTagsAiVar = makeVar<EmployeeRecordTagCategory[]>([]);

// category delete dialog
type EmployeeRecordTagCategoryDeleteDialog = {
  open: boolean;
  category: EmployeeRecordTagCategory | null;
};

const defaultEmployeeRecordTagCategoryDeleteDialog = {
  open: false,
  category: null,
};

export const employeeRecordTagCategoryDeleteDialogVar =
  makeVar<EmployeeRecordTagCategoryDeleteDialog>(
    defaultEmployeeRecordTagCategoryDeleteDialog,
  );

export const resetEmployeeRecordTagCategoryDeleteDialog = () => {
  employeeRecordTagCategoryDeleteDialogVar(
    defaultEmployeeRecordTagCategoryDeleteDialog,
  );
};

// subcategory delete dialog
type EmployeeRecordTagSubcategoryDeleteDialog = {
  open: boolean;
  categoryId: string;
  subcategory: EmployeeRecordTagSubcategory | null;
  failReason: DeleteEmployeeRecordTagSubcategoryFailReason | null;
};

const defaultEmployeeRecordTagSubcategoryDeleteDialog = {
  open: false,
  categoryId: '',
  subcategory: null,
  failReason: null,
};

export const employeeRecordTagSubcategoryDeleteDialogVar =
  makeVar<EmployeeRecordTagSubcategoryDeleteDialog>(
    defaultEmployeeRecordTagSubcategoryDeleteDialog,
  );

export const resetEmployeeRecordTagSubcategoryDeleteDialog = () => {
  employeeRecordTagSubcategoryDeleteDialogVar(
    defaultEmployeeRecordTagSubcategoryDeleteDialog,
  );
};

// category creation dialog
type EmployeeRecordTagCategoryCreationDialog = {
  open: boolean;
};

const defaultEmployeeRecordTagCategoryCreationDialog = {
  open: false,
};

export const employeeRecordTagCategoryCreationDialogVar =
  makeVar<EmployeeRecordTagCategoryCreationDialog>(
    defaultEmployeeRecordTagCategoryCreationDialog,
  );

export const resetEmployeeRecordTagCategoryCreationDialog = () => {
  employeeRecordTagCategoryCreationDialogVar(
    defaultEmployeeRecordTagCategoryCreationDialog,
  );
};

// category update dialog
type EmployeeRecordTagCategoryUpdateDialog = {
  open: boolean;
  category: EmployeeRecordTagCategory | null;
  categoryIndex: number;
};

const defaultEmployeeRecordTagCategoryUpdateDialog = {
  open: false,
  category: null,
  categoryIndex: -1,
};

export const employeeRecordTagCategoryUpdateDialogVar =
  makeVar<EmployeeRecordTagCategoryUpdateDialog>(
    defaultEmployeeRecordTagCategoryUpdateDialog,
  );

export const resetEmployeeRecordTagCategoryUpdateDialog = () => {
  employeeRecordTagCategoryUpdateDialogVar(
    defaultEmployeeRecordTagCategoryUpdateDialog,
  );
};

// subcategory update dialog
type EmployeeRecordTagSubcategoryUpdateDialog = {
  open: boolean;
  categoryId: string;
  subcategory: EmployeeRecordTagSubcategory | null;
  subcategoryIndex: number;
};

const defaultEmployeeRecordTagSubcategoryUpdateDialog = {
  open: false,
  categoryId: '',
  subcategory: null,
  subcategoryIndex: -1,
};

export const employeeRecordTagSubcategoryUpdateDialogVar =
  makeVar<EmployeeRecordTagSubcategoryUpdateDialog>(
    defaultEmployeeRecordTagSubcategoryUpdateDialog,
  );

export const resetEmployeeRecordTagSubcategoryUpdateDialog = () => {
  employeeRecordTagSubcategoryUpdateDialogVar(
    defaultEmployeeRecordTagSubcategoryUpdateDialog,
  );
};
