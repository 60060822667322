import React, { memo } from 'react';
import { Box } from '@mui/material';
import { Checkbox, IconButton } from '../../../../../newComponents';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';
import { usePermissionChecker, useScreenSize } from '../../../../../Hooks';
import {
  TagNameCell,
  UploadDocForEmpCell,
  ActionCell,
} from './DocumentChecklist.styles';
import { RenderEmployeeRecordType } from '../../../../RHPod/Documents/Tags/ExpandTags';
import {
  ADMIN_ACTION_EMPLOYEE_UPLOAD_FILE,
  ADMIN_ACTIONS_CHECKLIST,
} from './DocumentChecklist.constants';
import { DocumentCheckListAdminSelector } from './DocumentCheckListAdminSelector';

export const DocumentChecklistTableRowItem = memo(
  /** @param {import('./DocumentChecklist.types').DocumentChecklistTableRowItemProps} props */
  (props) => {
    const { tag, onSelectTag, onRemoveTag, onChangeDocumentAction } = props;
    const { isMobile } = useScreenSize();
    const [userHasPermissionToEdit, userCanRequestDocsToEmployees] =
      usePermissionChecker([
        {
          permission: 'recordChecklist',
          action: 'update',
        },
        {
          permission: 'requestDocsToEmployees',
          action: 'update',
        },
      ]);

    return (
      <React.Fragment>
        <TagNameCell>
          <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
            <Checkbox
              color="success"
              checked={tag.selected}
              onChange={() => onSelectTag(tag)}
              disabled={!userHasPermissionToEdit}
            />
          </TooltipAccessWrapper>
          <Box sx={{ width: '170px' }}>
            <RenderEmployeeRecordType
              params={{
                row: {
                  category: tag.documentCategoryName,
                  subcategory: tag.documentSubcategoryName,
                  documentAction: tag.documentAction,
                },
              }}
              handleOpen={() => {}}
              columnWidth={isMobile ? undefined : 350}
            />
          </Box>
        </TagNameCell>
        <UploadDocForEmpCell>
          {userCanRequestDocsToEmployees ? (
            <DocumentCheckListAdminSelector
              tag={tag}
              onChange={onChangeDocumentAction}
              disabled={!userHasPermissionToEdit}
              options={ADMIN_ACTIONS_CHECKLIST}
            />
          ) : (
            <TooltipAccessWrapper accessAllowed={userCanRequestDocsToEmployees}>
              <DocumentCheckListAdminSelector
                tag={tag}
                onChange={undefined}
                disabled
                options={[ADMIN_ACTION_EMPLOYEE_UPLOAD_FILE]}
              />
            </TooltipAccessWrapper>
          )}
        </UploadDocForEmpCell>
        <ActionCell>
          <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
            <IconButton
              icon="delete_bin_5_line"
              color="error"
              sx={{ visibility: 'hidden' }}
              onClick={() => onRemoveTag(tag)}
              disabled={!userHasPermissionToEdit}
            />
          </TooltipAccessWrapper>
        </ActionCell>
      </React.Fragment>
    );
  },
);
