import { gql } from '@apollo/client';

export const GET_REQUESTED_EMPLOYEE_DOCUMENTS = gql`
  query GetRequestedEmployeeDocuments {
    getRequestedEmployeeDocuments {
      _id
      category
      subcategory
      status
      history {
        action
        comment
        createdAt
        createdBy {
          _id
          names
          lastNameP
          lastNameM
          rfc
          curp
          fullName
          profilePicture
        }
      }
      aiResponse {
        type
        message
      }
    }
  }
`;

export const UPLOAD_PENDING_REQUESTED_EMPLOYEE_DOCUMENT = gql`
  mutation UploadPendingDocument(
    $file: Upload!
    $input: UploadPendingDocumentInput!
  ) {
    uploadPendingDocument(file: $file, input: $input) {
      _id
      documentId
      title
      category
      subcategory
    }
  }
`;

export const VERIFY_PENDING_REQUESTED_EMP_DOC_WITH_AI = gql`
  mutation verifyPendingDocumentWithAi(
    $file: Upload!
    $input: VerifyPendingDocumentWithAiInput!
  ) {
    verifyPendingDocumentWithAi(file: $file, input: $input) {
      ... on ErrorToVerifyPendingDocumentWithAi {
        message
      }
      ... on LimitExceededError {
        message
      }
      ... on VerifyPendingDocumentWithAiResult {
        success
        message
        satResponse {
          status
          type
          webScrapingData {
            CIF
            RFC
            startDateOfOperations
            lastStatusChangeDate
            tradeName
            statusInRegistry
            CURP
            names
            lastNameP
            lastNameM
            zipCode
            street
            colony
            city
            state
            externalNumber
            internalNumber
            localityName
            roadType
          }
          documentData {
            CIF
            RFC
            startDateOfOperations
            lastStatusChangeDate
            tradeName
            statusInRegistry
            CURP
            names
            lastNameP
            lastNameM
            zipCode
            street
            colony
            city
            state
            externalNumber
            internalNumber
            localityName
            roadType
          }
        }
        imssResponse {
          status
          type
          nss
        }
      }
    }
  }
`;
