import { Grid, Stack } from '@mui/material';
import {
  ControlledBirthdateInput,
  ControlledGenderInput,
  ControlledMaritalStatusInput,
} from '../../../ControlledInputs';
import type { CompleteOnboardingPersonalDataFormProps } from './ConfirmPersonalDataAi.types';
import { Typography } from '../../../../newComponents';
import { useEffect } from 'react';

export const ConfirmPersonalDataAiForm = (
  props: CompleteOnboardingPersonalDataFormProps,
) => {
  const { form } = props;

  const { trigger } = form;

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <Stack flex="1" spacing={1}>
      <Stack flex="1">
        <Typography variant="caption">
          Los siguientes datos fueron extraídos de la constancia de situación
          fiscal, confirma que sean los correctos.
        </Typography>
      </Stack>
      <Stack flex="1">
        <Typography variant="subtitle1">Datos personales: </Typography>
        <Grid container spacing={3} mt={-2}>
          <Grid item xs={6}>
            <ControlledBirthdateInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{
                label: 'Fecha de nacimiento',
                placeholder: '',
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledGenderInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{ label: 'Sexo', fullWidth: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledMaritalStatusInput
              control={form.control}
              rules={{
                required: {
                  value: true,
                  message: 'Campo requerido',
                },
              }}
              textFieldProps={{ label: 'Estado civil', fullWidth: true }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
