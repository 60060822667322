import React, { memo } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { ListItem } from '../../../../../newComponents';
import { DocumentCatalogItem } from './DocumentCatalogItem';

export const DocumentCatalogItemWrapper = memo(
  /** @param {import('./DocumentCatalog.types').DocumentCatalogItemWrapperProps} props */
  (props) => {
    const { style, index, data } = props;
    const {
      documentCatalogTags,
      onSelectTag = () => {},
      onRemoveTag = () => {},
    } = data;
    const tag = documentCatalogTags[index];

    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: tag.id,
      data: { tag },
    });

    const itemStyle = {
      ...style,
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <ListItem
        ref={setNodeRef}
        style={itemStyle}
        {...attributes}
        {...listeners}
        sx={{
          cursor: 'grab',
          visibility: isDragging ? 'hidden' : 'visible',
          '&:hover .MuiButton-root': { visibility: 'visible' },
          '&:hover .MuiIconButton-root': { visibility: 'visible' },
        }}
      >
        <DocumentCatalogItem
          tag={tag}
          onSelectTag={onSelectTag}
          onRemoveTag={onRemoveTag}
        />
      </ListItem>
    );
  },
);
