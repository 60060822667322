import { useMutation, useApolloClient } from '@apollo/client';
import {
  UPLOAD_PENDING_REQUESTED_EMPLOYEE_DOCUMENT,
  GET_REQUESTED_EMPLOYEE_DOCUMENTS,
} from '../RequestedEmployeeDocumentsDialog.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { COMPLETE_PERSONAL_INFORMATION } from '../../CompletePersonalInformationDialog/CompletePersonalInformationDialog.gql';
import type { HandleUpdateUserDataAndUploadPendingDocumentInput } from '../ConfirmPersonalData/ConfirmPersonalDataAi.types';

export const useUpdateUserDataAndUploadPendingDocument = () => {
  const client = useApolloClient();

  const [completePersonalInformation] = useMutation(
    COMPLETE_PERSONAL_INFORMATION,
  );
  const [uploadPendingDocument] = useMutation(
    UPLOAD_PENDING_REQUESTED_EMPLOYEE_DOCUMENT,
  );

  const handleUpdateUserDataAndUploadPendingDocument = async (
    input: HandleUpdateUserDataAndUploadPendingDocumentInput,
  ) => {
    const { documentData, dataToUpdate, formType, onSuccess } = input;

    const personalData = dataToUpdate.personalData.getValues() ?? {};
    const addressData = dataToUpdate.addressData.getValues() ?? {};
    const nssData = dataToUpdate.nssData.getValues() ?? {};

    try {
      globalBackdropVar({
        open: true,
        text: 'Actualizando información y subiendo documento...',
      });

      if (formType === 'PERSONAL_DATA') {
        await completePersonalInformation({
          variables: {
            input: { ...personalData, address: addressData },
          },
        });
      } else {
        await completePersonalInformation({
          variables: {
            input: { ...nssData },
          },
        });
      }

      await uploadPendingDocument({
        variables: {
          file: documentData.file,
          input: {
            _id: documentData._id,
            category: documentData.category,
            subcategory: documentData.subcategory,
            uploadDocumentWithAi: true,
          },
        },
      });

      globalSnackbarVar({
        show: true,
        message: 'Información actualizada con éxito',
        severity: 'success',
      });

      globalBackdropVar({ open: false });
      onSuccess && onSuccess();

      const queries = [GET_REQUESTED_EMPLOYEE_DOCUMENTS];
      await client.refetchQueries({ include: queries });
    } catch (error) {
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        message:
          'Ocurrió un error al actualizar la información y subir el archivo',
        severity: 'error',
      });
    }
  };

  return { handleUpdateUserDataAndUploadPendingDocument };
};
