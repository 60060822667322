import { useReactiveVar } from '@apollo/client';
import { employeeRecordTagsAiVar } from '../containers/Settings/Preferences/EmployeeRecordTagsCatalog/EmployeeRecordTagsCatalog.vars';
import {
  AiModule,
  getActiveAiByCategory,
} from '../containers/Settings/Preferences/EmployeeRecordTagsCatalog/EmployeeRecordTagsCatalog.helpers';

type GetActiveIdInput = {
  categoryId: string;
  subcategoryId: string;
  ai: AiModule;
};

export const useGetActiveAi = (input: GetActiveIdInput): Boolean => {
  const tags = useReactiveVar(employeeRecordTagsAiVar);
  const { categoryId, subcategoryId, ai } = input;

  const category = tags?.find(
    (item) =>
      item?._id === categoryId ||
      item?.category?.toLowerCase() === categoryId?.toLowerCase(),
  );

  const subCategories = category?.subcategories ?? [];
  const subcategoryFound = subCategories?.find(
    (i) =>
      i?._id === subcategoryId ||
      i?.subcategory?.toLowerCase() === subcategoryId?.toLowerCase(),
  );

  if (!subcategoryFound || !category) return false;

  let { isTagDisabledByModuleConfiguration } = getActiveAiByCategory({
    ai: ai,
    categoryName: category.category,
    subcategoryName: subcategoryFound.subcategory,
  });

  return Boolean(isTagDisabledByModuleConfiguration);
};
