import { useState } from 'react';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CompletePersonalDataForm } from './CompletePersonalInformationForms/CompletePersonalDataForm';
import { CompleteAddressForm } from './CompletePersonalInformationForms/CompleteAddressForm';
import { useGetUserInfoRequestedByCompany } from './CompletePersonalInformationHooks/useGetUserInfoRequestedByCompany';
import { useCompletePersonalInformation } from './CompletePersonalInformationHooks/useCompletePersonalInformation';
import {
  PERSONAL_INFO_TYPE,
  DEFAULT_ADDRESS_DATA,
} from './CompletePersonalInformationDialog.constants';

export const CompletePersonalInformationContent = () => {
  const [step, setStep] = useState(0);
  const { handleCompletePersonalData, handleCompleteAddress } =
    useCompletePersonalInformation();

  const { userInfoRequestedByCompany } = useGetUserInfoRequestedByCompany();

  const addressRequired = userInfoRequestedByCompany.find(
    (info) => info.type === PERSONAL_INFO_TYPE.ADDRESS,
  );

  const defaultPersonalInfoValues = userInfoRequestedByCompany.reduce(
    (acc, info) => {
      if (info.type === PERSONAL_INFO_TYPE.BIRTHDATE) {
        acc['birthdate'] = '';
      }
      if (info.type === PERSONAL_INFO_TYPE.GENDER) {
        acc['gender'] = '';
      }
      if (info.type === PERSONAL_INFO_TYPE.MARITAL_STATUS) {
        acc['maritalStatus'] = '';
      }
      return acc;
    },
    {},
  );

  const userPersonalInfoForm = useForm({
    mode: 'onChange',
    defaultValues: defaultPersonalInfoValues,
  });

  const userAddressForm = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_ADDRESS_DATA,
  });

  const somePersonalInfoRequested =
    Object.keys(defaultPersonalInfoValues).length > 0;

  const showCompletePersonalDataForm = somePersonalInfoRequested && step === 0;
  const showCompleteAddressForm =
    (addressRequired && !somePersonalInfoRequested) ||
    (addressRequired && step === 1);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '500px' }}
    >
      {showCompletePersonalDataForm && (
        <CompletePersonalDataForm
          form={userPersonalInfoForm}
          onSubmit={() => {
            if (addressRequired) return setStep(1);
            handleCompletePersonalData(userPersonalInfoForm);
          }}
          userInfoRequestedByCompany={userInfoRequestedByCompany}
        />
      )}
      {showCompleteAddressForm && (
        <CompleteAddressForm
          form={userAddressForm}
          onSubmit={() => {
            handleCompleteAddress(userPersonalInfoForm, userAddressForm);
          }}
        />
      )}
    </Stack>
  );
};
