import { useReactiveVar } from '@apollo/client';
import { companyModulesVar } from '../cache.reactiveVars';

type BasicModule = {
  active: boolean;
};

type AiModule = BasicModule & {
  getTaxCertificateData: boolean;
  getImssCertificateData: boolean;
};

type CompanyModules = {
  ai: AiModule;
  documents: BasicModule;
  holidays: BasicModule;
  rest: BasicModule;
};

export const useCompanyModules = (): CompanyModules => {
  const modules = useReactiveVar(companyModulesVar);

  return {
    ai: {
      active: modules?.ai?.active ?? false,
      getImssCertificateData: modules?.ai?.getImssCertificateData ?? false,
      getTaxCertificateData: modules?.ai?.getTaxCertificateData ?? false,
    },
    documents: {
      active: modules?.documents?.active ?? false,
    },
    holidays: {
      active: modules?.holidays?.active ?? false,
    },
    rest: {
      active: modules?.rest?.active ?? false,
    },
  };
};
