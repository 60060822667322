export const ADMIN_ACTION_UPLOAD_FILE = {
  value: 'ADMIN_UPLOAD_DOC',
  label: 'El admin carga el archivo',
};

export const ADMIN_ACTION_EMPLOYEE_UPLOAD_FILE = {
  value: 'EMPLOYEE_UPLOAD_DOC',
  label: 'El empleado carga el archivo',
};

export const ADMIN_ACTIONS_CHECKLIST = [
  ADMIN_ACTION_UPLOAD_FILE,
  ADMIN_ACTION_EMPLOYEE_UPLOAD_FILE,
];
