// @ts-check
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { Stack, useTheme, useMediaQuery } from '@mui/material';
import {
  DndContext,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  closestCorners,
  DragOverlay,
} from '@dnd-kit/core';
import { Icon } from '../../../../../components/Icons/Icons';
import { Button } from '../../../../../newComponents/Button';
import { Typography } from '../../../../../newComponents/Typography';
import { DocumentOverlay } from '../DocumentOverlay';
import { DocumentChecklist } from '../DocumentChecklist';
import { DocumentCatalog } from '../DocumentCatalog';
import { useConfigureChecklistLogic } from '../hooks';
import { PaperStyled } from './ConfigureChecklistDialog.styles';
import { configureChecklistDialogVar } from '../RecordChecklist.vars';

export const ConfiguraChecklistDialogForm = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { isNewRecordChecklist } = useReactiveVar(configureChecklistDialogVar);

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 2 } }),
    useSensor(TouchSensor, {
      activationConstraint: { distance: 2, delay: 500 },
    }),
  );

  const {
    activeTag,
    documentCatalogTags,
    documentChecklistTags,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleChangeDocumentAction,
    handleRemoveTagFromChecklist,
    handleSelectTagFromChecklist,
    handleSelectAllTagsFromChecklist,
    handleRemoveTagFromCatalog,
    handleSelectTagFromCatalog,
    handleSelectAllTagsFromCatalog,
  } = useConfigureChecklistLogic();

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
    >
      <Stack flex="1" height="100%">
        <Stack flex="1" gap={3} height="100%">
          <Stack gap={1}>
            <Typography variant="h6">
              {isNewRecordChecklist
                ? ' 2. Configura el checklist del expediente'
                : 'Configura el checklist del expediente'}
            </Typography>
            <Typography variant="body2">
              Debes seleccionar o arrastrar los documentos desde tu catálogo de
              documentos hacia el checklist del expediente laboral.
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            flex={1}
            height="100%"
            width="100%"
          >
            <PaperStyled
              sx={{
                flexShrink: 0,
                width: { xs: '100%', md: '400px' },
                minHeight: '100%',
              }}
            >
              <DocumentCatalog
                documentCatalogTags={documentCatalogTags}
                onRemoveTag={handleRemoveTagFromCatalog}
                onSelectTag={handleSelectTagFromCatalog}
                onSelectAllTags={handleSelectAllTagsFromCatalog}
              />
            </PaperStyled>
            <Stack px={1} py={3} justifyContent="center">
              <Button variant="soft" color="default">
                <Icon
                  icon="arrow_right_line"
                  width="24px"
                  height="24px"
                  style={{
                    transform: isSmallScreen ? 'rotate(90deg)' : 'rotate(0deg)',
                  }}
                />
              </Button>
            </Stack>
            <PaperStyled
              sx={{
                flex: 1,
                minHeight: '100%',
              }}
            >
              <DocumentChecklist
                documentChecklistTags={documentChecklistTags}
                onChangeDocumentAction={handleChangeDocumentAction}
                onRemoveTag={handleRemoveTagFromChecklist}
                onSelectTag={handleSelectTagFromChecklist}
                onSelectAllTags={handleSelectAllTagsFromChecklist}
              />
            </PaperStyled>
          </Stack>
        </Stack>
      </Stack>
      <DragOverlay>
        {activeTag !== null && <DocumentOverlay tag={activeTag} />}
      </DragOverlay>
    </DndContext>
  );
};
