import { Grid, Stack } from '@mui/material';
import {
  ControlledCityInput,
  ControlledColonyInput,
  ControlledExternalNumberInput,
  ControlledInternalNumberInput,
  ControlledStateInput,
  ControlledStreetInput,
  ControlledZipCodeInput,
} from '../../../ControlledInputs';
import type { CompleteOnboardingAddressFormProps } from './ConfirmPersonalDataAi.types';
import { Typography } from '../../../../newComponents';
import { useEffect } from 'react';

export const ConfirmPersonalAddressAiForm = (
  props: CompleteOnboardingAddressFormProps,
) => {
  const { form } = props;
  const { watch, setValue, trigger } = form;

  const resetCountryDependentFields = () => {
    setValue('zipCode', '');
    setValue('state', '');
    setValue('city', '');
    setValue('colony', '');
  };

  const country = watch('country');
  const isZipCodeEmpty = !watch('zipCode');

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <Stack flex="1">
      <Typography variant="subtitle1">Dirección:</Typography>
      <Grid container spacing={3} mt={-2}>
        <Grid item xs={6}>
          <ControlledZipCodeInput
            control={form.control}
            country={country}
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
            textFieldProps={{
              label: 'Código postal',
              placeholder: '',
              disabled: !Boolean(country),
              fullWidth: true,
            }}
            onReceiveLocationData={(data) => {
              setValue('city', data.city);
              setValue('state', data.state);
              setValue('colony', data.colony);
              trigger();
            }}
            onClearZipCode={() => {
              resetCountryDependentFields();
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledStateInput
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
            textFieldProps={{
              label: 'Estado',
              required: true,
              disabled: isZipCodeEmpty,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledCityInput
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
            textFieldProps={{
              label: 'Ciudad',
              required: true,
              disabled: isZipCodeEmpty,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledColonyInput
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
            textFieldProps={{
              label: 'Colonia',
              required: true,
              disabled: isZipCodeEmpty,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledStreetInput
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
            textFieldProps={{
              label: 'Calle',
              required: true,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledExternalNumberInput
            control={form.control}
            rules={{
              required: {
                value: true,
                message: 'Campo requerido',
              },
            }}
            textFieldProps={{
              label: 'Número exterior',
              required: true,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <ControlledInternalNumberInput
            control={form.control}
            textFieldProps={{ label: 'Número interior', fullWidth: true }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
