import React, { Suspense } from 'react';
import RoleRoute from '../../utils/RoleRoute';
import { LoaderWrapper } from '../../components/DocumentSigner/style';
import { CircularProgress } from '../../newComponents';
import { useBlockActionsDueToOldestUnsignedReceipts } from './EmployeePortal/EmployeePortalHooks';
import { BlockReceiptsDueToUnsignedReceiptsDialog } from './EmployeePortal/BlockReceiptsDueToUnsignedReceiptsDialog';
import { BlockDocumentsDueToUnsignedReceiptsDialog } from './EmployeePortal/BlockDocumentsDueToUnsignedReceiptsDialog';
import { useCompanyModules } from '../../Hooks/useCompanyModules';
const MyPortal = React.lazy(() => import('./EmployeePortal/MyPortal/MyPortal'));
const MyRecord = React.lazy(() => import('./EmployeePortal/MyRecord/MyRecord'));
const MyCredits = React.lazy(
  () => import('./EmployeePortal/MyCredits/MyCredits'),
);

const MyCalendar = React.lazy(
  () => import('./EmployeePortal/MyCalendar/MyCalendar'),
);

export function RHPodEmployeesRoutes({ hideCreditsSection, roleURL }) {
  useBlockActionsDueToOldestUnsignedReceipts();
  const {
    holidays: { active: isHolidaysModuleActive },
  } = useCompanyModules();

  return (
    <>
      <BlockReceiptsDueToUnsignedReceiptsDialog />
      <BlockDocumentsDueToUnsignedReceiptsDialog />
      <RoleRoute employee exact path="/rhpod/empleado">
        <Suspense
          fallback={
            <LoaderWrapper style={{ display: 'flex', flexDirection: 'column' }}>
              <CircularProgress size={50} />
            </LoaderWrapper>
          }
        >
          <MyPortal />
        </Suspense>
      </RoleRoute>
      <RoleRoute employee exact path="/rhpod/empleado/documentos">
        <Suspense
          fallback={
            <LoaderWrapper style={{ display: 'flex', flexDirection: 'column' }}>
              <CircularProgress size={50} />
            </LoaderWrapper>
          }
        >
          <MyRecord />
        </Suspense>
      </RoleRoute>
      {!hideCreditsSection && (
        <RoleRoute employee exact path="/rhpod/empleado/creditos">
          <Suspense
            fallback={
              <LoaderWrapper
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <CircularProgress size={50} />
              </LoaderWrapper>
            }
          >
            <MyCredits />
          </Suspense>
        </RoleRoute>
      )}

      {isHolidaysModuleActive && (
        <RoleRoute employee exact path="/rhpod/empleado/calendario">
          <Suspense
            fallback={
              <LoaderWrapper
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <CircularProgress size={50} />
              </LoaderWrapper>
            }
          >
            <MyCalendar></MyCalendar>
          </Suspense>
        </RoleRoute>
      )}
    </>
  );
}
