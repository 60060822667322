// @ts-check
import { Stack } from '@mui/material';
import { ControlledSocialSecurityNumberInput } from '../../../ControlledInputs';
import type { CompleteOnboardingNssFormProps } from './ConfirmPersonalDataAi.types';
import { Typography } from '../../../../newComponents';

export const ConfirmPersonalNssAiForm = (
  props: CompleteOnboardingNssFormProps,
) => {
  const { form } = props;

  return (
    <Stack flex="1">
      <Stack flex="1">
        <Typography variant="caption">
          Los siguientes datos fueron extraídos de la constancia de seguro
          social, confirma que sean los correctos.
        </Typography>
      </Stack>
      <Stack flex="1" mt={2} spacing={1}>
        <Typography variant="subtitle1">Datos del seguro social:</Typography>
        <ControlledSocialSecurityNumberInput
          control={form.control}
          textFieldProps={{
            label: 'Número de seguro social',
            fullWidth: true,
          }}
        />
      </Stack>
    </Stack>
  );
};
