// @ts-check
import React from 'react';
import { styled } from '@mui/system';
import { PickersDay } from '@mui/x-date-pickers';
import { publicSans } from '../../components/Typographies/Fonts';

/**
 * @typedef {import("../../theme").CustomTheme} CustomTheme
 */

/**
 * @param {object} props
 * @param {CustomTheme} props.theme
 */
const styleBuilder = ({ theme }) => ({
  fontFamily: publicSans,
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '22px',
  letterSpacing: '0px',

  '&.MuiPickersDay-root': {
    '&.Mui-selected': {
      backgroundColor: theme.newPalette.primary.main,
    },
  },
});

const StyledPickersDay = styled(PickersDay)(styleBuilder);

export const renderPickersDay = (date, selectedDates, pickersDayProps) => {
  return <StyledPickersDay {...pickersDayProps}></StyledPickersDay>;
};
