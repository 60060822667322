import { useLazyQuery, useReactiveVar } from '@apollo/client';
import {
  companyModulesVar,
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';
import { useCallback } from 'react';
import { GET_COMPANY_MODULES } from '../../../Company/company.gql';
import { employeeRecordTagsAiVar } from '../../../../Settings/Preferences/EmployeeRecordTagsCatalog/EmployeeRecordTagsCatalog.vars';

// export type GetTemplateInput = {
//   templateId: string;
//   onSuccess: (template: TemplateRowDetail) => void;
// };

export const useGetCompanyModules = () => {
  const company = useReactiveVar(currentCompanyVar);
  const [getCompanyModules] = useLazyQuery(GET_COMPANY_MODULES, {
    fetchPolicy: 'cache-and-network',
  });

  const handleGetCompanyModules = useCallback(async () => {
    try {
      const response = await getCompanyModules({
        query: GET_COMPANY_MODULES,
        variables: {
          filter: {
            companyId: company?._id,
          },
        },
        fetchPolicy: 'network-only',
      });

      const data = response?.data?.getCompanyModules;

      if (data?.__typename === 'CompanyModulesResult') {
        companyModulesVar(data.modules);
        employeeRecordTagsAiVar(data.employeeRecordTags ?? []);
      } else {
        companyModulesVar(null);
      }
    } catch (error) {
      globalSnackbarVar({
        show: true,
        severity: 'error',
        message: 'Ocurrió un error al obtener modulos, contacte a Sora',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  }, [getCompanyModules, company]);

  return { handleGetCompanyModules };
};
