// @ts-check
import React from 'react';
import { TableHead, useTheme } from '@mui/material';
import { IconButton } from '../../../../../newComponents/IconButton';
import { Typography } from '../../../../../newComponents/Typography';
import { TooltipAccessWrapper } from '../../../../../businessComponents/TooltipAccessWrapper';
import { Checkbox } from '../../../../../newComponents/Checkbox';
import { usePermissionChecker } from '../../../../../Hooks';
import {
  StyledTableRow,
  StyledTableCell,
  TagOrderCell,
  TagNameCell,
  UploadDocForEmpCell,
  ActionCell,
} from './DocumentChecklist.styles';

/** @param {import('./DocumentChecklist.types').DocumentChecklistTableHeaderProps} props */
export const DocumentChecklistTableHeader = (props) => {
  const { documentChecklistTags, onSelectAllTags, onRemoveSelectedTags } =
    props;

  /** @type {import('../../../../../theme').CustomTheme} */
  const theme = useTheme();

  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'recordChecklist',
    action: 'update',
  });

  const allSelected = documentChecklistTags.every((tag) => tag.selected);
  const countSelected = documentChecklistTags.filter(
    (tag) => tag.selected,
  ).length;
  const someSelected = countSelected > 0;

  if (someSelected) {
    return (
      <TableHead
        component="div"
        sx={{ backgroundColor: theme.newPalette.success.lighter }}
      >
        <StyledTableRow sx={{ position: 'relative', overflow: 'auto' }}>
          <TagOrderCell></TagOrderCell>
          <TagNameCell>
            <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
              <Checkbox
                color="success"
                onChange={onSelectAllTags}
                checked={allSelected && someSelected}
                indeterminate={someSelected && !allSelected}
                disabled={!userHasPermissionToEdit}
              />
            </TooltipAccessWrapper>
            <Typography
              variant="body2"
              color="success.dark"
              style={{
                fontWeight: 700,
                fontSize: '13px',
              }}
            >
              {`${countSelected} seleccionados`}
            </Typography>
          </TagNameCell>
          <UploadDocForEmpCell></UploadDocForEmpCell>
          <ActionCell>
            <IconButton
              icon="delete_bin_5_line"
              color="error"
              onClick={() => onRemoveSelectedTags()}
            />
          </ActionCell>
        </StyledTableRow>
      </TableHead>
    );
  }

  return (
    <TableHead
      component="div"
      sx={{ backgroundColor: theme.newPalette.success.lighter }}
    >
      <StyledTableRow sx={{ position: 'relative', overflow: 'auto' }}>
        <StyledTableCell
          sx={{ pl: 2, pr: 1, minWidth: '50px' }}
        ></StyledTableCell>
        <TagNameCell>
          <TooltipAccessWrapper accessAllowed={userHasPermissionToEdit}>
            <Checkbox
              color="success"
              onChange={onSelectAllTags}
              checked={allSelected && someSelected}
              indeterminate={someSelected && !allSelected}
              disabled={!userHasPermissionToEdit}
            />
          </TooltipAccessWrapper>
          <Typography
            variant="body2"
            color="success.dark"
            style={{ fontWeight: 600, fontSize: '13px' }}
          >
            {`Etiqueta`}
          </Typography>
        </TagNameCell>
        <UploadDocForEmpCell>
          <Typography
            variant="body2"
            color="success.dark"
            style={{ fontWeight: 600, fontSize: '13px' }}
          >
            Acciones
          </Typography>
        </UploadDocForEmpCell>
        <ActionCell></ActionCell>
      </StyledTableRow>
    </TableHead>
  );
};
