import React, { useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { EditHolidaySchemeDialogFormType } from './EditHolidaySchemeDialog.types';
import { Stack } from '@mui/material';
import { Employee } from '../../../../../../../businessComponents/EmployeesFinder/EmployeesFinder';
import { EmployeesFinder } from '../../../../../../../businessComponents/EmployeesFinder';
import {
  DeleteActions,
  RowBaseItem,
  TableComponent,
} from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableComponent';
import { COLUMNS_TABLE_SIGNERS } from '../../../AuthorizersTeams/components/AddAuthorizersDialog/setEmployeesSlide/TableColumns';
import { SignersTabs } from '../../../../../../RHPod/Documents/PdfDocuments/Signers/SignersTabs';
import { ConfirmDialog } from '../../../../../../../newComponents';
import { useTheme } from '@mui/material/styles';
import { useEHSEmployeesActions } from './useEHSEmployeesActions';
type EHSEmployeesProps = {
  form: UseFormReturn<EditHolidaySchemeDialogFormType>;
};
export const EHSEmployees: React.FC<EHSEmployeesProps> = ({ form }) => {
  const theme = useTheme();
  const palette = theme.newPalette;

  const { watch } = form;
  const preselectedScheme = watch('selectedScheme');

  const [isOpenDeleteDialog, setIsOpenHolidayDialog] = useState(false);

  const {
    states: {
      rows,
      setSelectedRows,
      setErrorRows,
      userAddedRows,
      errorRows,
      selectedRows,
      setTableTab,
      tableTab,
      setUserAddedRows,
      isCheckedAll,
      preloadedEmployeesTotal,
    },
    actions: { handleLoadMore, addRow, deleteRows, handleCheckAllRows },
  } = useEHSEmployeesActions({
    preselectedScheme,
    onDeleteRows: () => setIsOpenHolidayDialog(false),
  });

  const deleteItemRef = useRef<null | RowBaseItem[]>(null);

  const modifiedColumns = COLUMNS_TABLE_SIGNERS.map((column) => ({
    ...column,
  }));

  const deleteRowsAction = (action: DeleteActions, row: RowBaseItem) => {
    if (tableTab === 'success') {
      if (action === 'massive') {
        deleteItemRef.current = selectedRows;
      } else {
        deleteItemRef.current = [row];
      }
      setIsOpenHolidayDialog(true);
    } else {
      if (action === 'massive') {
        const selectedRowsIds = selectedRows.map((r) => r.user);
        setErrorRows(
          errorRows.filter((r) => !selectedRowsIds.includes(r.user)),
        );
        setSelectedRows([]);
      } else {
        setErrorRows(errorRows.filter((r) => r.user !== row.user));
      }
    }
  };

  const handleOnSelectedRowsOnPaste = (foundRows) => {
    const uniqueRows = foundRows
      .filter((employee) => {
        return !rows.some((prevEmployee) => prevEmployee._id === employee._id);
      })
      .map((row) => ({ ...row, type: 'Employee' })); // this because the row is coming from the paste
    setUserAddedRows((prev) => prev.concat(uniqueRows));
  };

  const handlePasterErrors = (errors) => {
    const uniqueErrors = errors.filter((employee) => {
      return !errorRows.some(
        (prevEmployee) => prevEmployee.user === employee.user,
      );
    });
    setErrorRows(uniqueErrors);
  };

  useEffect(() => {
    form.setValue('assignedTo', userAddedRows);
  }, [form, userAddedRows]);

  useEffect(() => {
    setSelectedRows([]);
  }, [tableTab, setSelectedRows]);

  useEffect(() => {
    if (errorRows.length === 0) {
      setTableTab('success');
    }
    form.setValue('assignedToErrors', errorRows);
  }, [errorRows, form, setTableTab]);

  return (
    <Stack mt={2} gap={2}>
      <EmployeesFinder
        findBy={['EMPLOYEES', 'WORKCENTERS', 'WORKTITLES', 'GROUPS']}
        onSelectRow={(row) => {
          addRow(row);
        }}
        selectedRows={rows}
        isEmployeeSelected={(employee: Employee) =>
          rows.some((row) => row._id === employee._id)
        }
        onSelectedRowsOnPaste={handleOnSelectedRowsOnPaste}
        handlePasterErrors={handlePasterErrors}
      />
      <SignersTabs
        signersList={rows}
        signersError={errorRows}
        tab={tableTab}
        handleChange={(e, v) => setTableTab(v)}
      />
      <TableComponent
        width="100%"
        height={300}
        headerBgColor={palette.grey.transparent24}
        headerTextColor={palette.text.secondary}
        rows={tableTab === 'success' ? rows : errorRows}
        selectedRows={selectedRows}
        columns={modifiedColumns}
        handleSelectAllCheckbox={handleCheckAllRows}
        handleCheckboxChange={(checked, row) => {
          if (checked) {
            setSelectedRows([...selectedRows, row]);
          } else {
            setSelectedRows(
              selectedRows.filter((selectedRow) => selectedRow !== row),
            );
          }
        }}
        areAllChecked={isCheckedAll}
        handleLoadMore={tableTab === 'success' ? handleLoadMore : () => {}}
        loadedRowCount={rows.length}
        deleteRows={deleteRowsAction}
        totalRowsCount={
          tableTab === 'success'
            ? preloadedEmployeesTotal + userAddedRows.length
            : errorRows.length
        }
      />
      <ConfirmDialog
        title={'Eliminar empleados de este esquema de vacaciones'}
        open={isOpenDeleteDialog}
        icon={'delete_bin_line'}
        confirmLabel={'Eliminar'}
        ConfirmButtonProps={{
          color: 'error',
        }}
        cancelLabel={'Cancelar'}
        onClose={() => setIsOpenHolidayDialog(false)}
        onConfirm={async () => {
          await deleteRows(deleteItemRef.current);
        }}
        onCancel={() => setIsOpenHolidayDialog(false)}
      />
    </Stack>
  );
};
