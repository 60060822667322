import { memo, useState } from 'react';
import { Stack, Box, alpha } from '@mui/material';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from '../../../../../newComponents';
import { Icon } from '../../../../../components/Icons/Icons';
import { EmployeeRecordTagSubcategory } from '../EmployeeRecordTagSubcategory';
import { EmployeeRecordTagCategoryMenu } from '../EmployeeRecordTagCategoryMenu';
import { EmployeeRecordTagSubcategoryCreationMiniForm } from '../EmployeeRecordTagSubcategoryCreationMiniForm';
import { EmployeeRecordTagCategoryUpdateMiniForm } from '../EmployeeRecordTagCategoryUpdateMiniForm';
import { CategoryContainer } from './EmployeeRecordTagCategory.styles';
import { supportDialogVar } from '../../../../../businessComponents/SupportDialog/SupportDialog.vars';
import {
  useBlockableActions,
  SORA_MODULES,
} from '../../../../../components/ModuleBlockableComponent';
import {
  ABSENCE_CATEGORY,
  BLOCK_CATEGORIES,
} from '../EmployeeRecordTagsCatalog.constants';
import type {
  CategoryDraggableContainerProps,
  EmployeeRecordTagCategoryProps,
} from './EmployeeRecordTagCategory.types';
import { ModulesAvailable } from '../../../../../components/ModuleBlockableComponent/useBlockableActions';

const CategoryDraggableContainer = (props: CategoryDraggableContainerProps) => {
  const { category, children } = props;
  const { dndLocked } = category;

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id: category.id,
    disabled: dndLocked,
    data: {
      type: 'category',
    },
  });

  return (
    <CategoryContainer
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      gap={2}
      style={{
        transition,
        backgroundColor: alpha(category.color, 0.5),
        opacity: isDragging ? 0 : 1,
        transform: dndLocked ? '' : CSS.Translate.toString(transform),
        cursor: dndLocked ? 'not-allowed' : 'grab',
      }}
    >
      {children}
    </CategoryContainer>
  );
};

export const EmployeeRecordTagCategory = memo(
  (props: EmployeeRecordTagCategoryProps) => {
    const { category, categoryIndex } = props;
    const [showNewSubcategoryForm, setShowNewSubcategoryForm] = useState(false);
    const {
      isModuleAvailable: isDocumentsModuleAvailable,
      onClick: documentsModuleBlockedOnClick,
    } = useBlockableActions(SORA_MODULES.DOCUMENTS as ModulesAvailable);

    const handleShowNewSubcategoryForm = () => {
      if (!isDocumentsModuleAvailable) {
        return documentsModuleBlockedOnClick();
      }
      if (category.category === ABSENCE_CATEGORY) {
        supportDialogVar({
          open: true,
          title: 'Agregar tipo de documento',
          description:
            'Contacta a soporte para agregar un tipo de documento a la categoria "Ausencias".',
        });
        return;
      }
      setShowNewSubcategoryForm(true);
    };

    const updateLocked = BLOCK_CATEGORIES.includes(category.category);

    return (
      <CategoryDraggableContainer category={category}>
        <Stack direction="row" gap={1} alignItems="center">
          <EmployeeRecordTagCategoryUpdateMiniForm
            category={category}
            categoryIndex={categoryIndex}
            disabled={updateLocked}
          />
          {!updateLocked && (
            <Box>
              <EmployeeRecordTagCategoryMenu
                category={category}
                categoryIndex={categoryIndex}
              />
            </Box>
          )}
        </Stack>
        <Stack>
          <Button
            variant="outlined"
            size="medium"
            color="default"
            endIcon={<Icon icon="add_line" />}
            sx={{
              borderColor: ({ newPalette }) =>
                `${newPalette.grey.transparent32} !important`,
            }}
            onClick={handleShowNewSubcategoryForm}
          >
            Agregar tipo de documento
          </Button>
        </Stack>
        <SortableContext
          strategy={verticalListSortingStrategy}
          items={category.subcategories}
        >
          <Stack gap={2} sx={{ overflow: 'auto', height: '100%' }}>
            {showNewSubcategoryForm && (
              <EmployeeRecordTagSubcategoryCreationMiniForm
                categoryId={category._id}
                onClose={() => setShowNewSubcategoryForm(false)}
              />
            )}
            {category.subcategories.map((sub, index) => (
              <EmployeeRecordTagSubcategory
                key={sub._id}
                subcategory={sub}
                categoryId={category._id}
                subcategoryIndex={index}
              />
            ))}
          </Stack>
        </SortableContext>
      </CategoryDraggableContainer>
    );
  },
);
