// @ts-check
import { MenuItem as MuiMenuItem } from '@mui/material';
import { Tooltip } from '../Tooltip';

/**
 * @typedef {import('@mui/material/MenuItem').MenuItemProps} MenuItemProps
 */
/**
 * MenuItem wrapper component
 * @param {MenuItemProps & {tooltip?: string}} props
 */
export const MenuItem = (props) => {
  const { tooltip, ...rest } = props;
  if (tooltip) {
    return (
      <>
        <Tooltip title={tooltip} placement="top">
          <span>
            <MuiMenuItem {...rest} />
          </span>
        </Tooltip>
      </>
    );
  }
  return <MuiMenuItem {...rest} />;
};
