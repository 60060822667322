//@ts-check
import { useQuery, useReactiveVar } from '@apollo/client';
import {
  currentCompanyVar,
  globalBackdropVar,
} from '../../../../../../cache.reactiveVars';
import { useEffect, useState } from 'react';
import { ALL_TEMPLATES_FROM_TABLE } from '../../../../../RHPod/Documents/gql';
import {
  openTemplatesListDialogVar,
  resetOpenTemplatesListDialogVar,
} from '../Templates.vars';
import { Dialog } from '../../../../../../components/Dialogs/Dialog';
import { DialogContent, DialogTitle, Stack } from '@mui/material';
import { IconButton } from '../../../../../../newComponents/IconButton';
import { Typography } from '../../../../../../newComponents/Typography';
import { SearchForm } from '../../../../../../components/Inputs/SearchForm';
import { ToggleButtonGroup } from '../../../../../../newComponents/ToggleButtonGroup';
import { ToggleButton } from '../../../../../../newComponents/ToggleButton';
import { Icon } from '../../../../../../components/Icons/Icons';
import { TemplatesListCards } from './TemplatesListCards';
import { TemplatesSimpleTable } from './TemplatesSimpleTable';
import { TemplatePreviewDialog } from '../TemplatesPreview/TemplatePreviewDialog';

export const TemplatesListContainerDialog = () => {
  const company = useReactiveVar(currentCompanyVar);
  const { open, onOpenTemplate } = useReactiveVar(openTemplatesListDialogVar);
  const [search, setSearch] = useState('');
  const [viewMode, setViewMode] = useState('list');
  const [templates, setTemplates] = useState({ total: 0, templates: [] });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const { loading, fetchMore } = useQuery(ALL_TEMPLATES_FROM_TABLE, {
    skip: !open,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      page: page,
      perPage: perPage,
      filter: {
        companyId: company?._id,
        search: search,
        status: 'PUBLISHED',
      },
    },
    onCompleted: ({ allTemplates }) => {
      setTemplates(allTemplates);
    },
  });

  const handleClose = () => {
    resetOpenTemplatesListDialogVar();
  };

  const handleLoadMore = (startIndex) => {
    const newPage = Math.ceil(startIndex / perPage);
    const rowsLoaded = templates.templates.length;
    if (rowsLoaded === templates.total) return;
    fetchMore({ variables: { perPage: perPage, page: newPage } });
  };

  useEffect(() => {
    if (!loading) {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={handleClose}
      showCloseButton={false}
      zoomTransition={false}
    >
      <DialogTitle component="div">
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton icon="close_line" onClick={handleClose} />
          <Typography variant="h6">Seleccionador de plantillas</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={{ p: '24px' }}>
        <Stack width="100%" direction="row" alignItems="center" spacing={2}>
          <SearchForm
            placeholder="Buscar plantilla"
            fullWidth={true}
            handleSearch={setSearch}
            sanitizers={['toLowerCase']}
          />
          <ToggleButtonGroup
            value={viewMode === 'list' ? 'right' : 'left'}
            exclusive
            color="default"
          >
            <ToggleButton
              value="left"
              sx={{ borderRadius: '10px' }}
              onClick={() => setViewMode('table')}
            >
              <Icon icon="list_unordered" width="20px" height="20px" />
            </ToggleButton>
            <ToggleButton
              value="right"
              sx={{ borderRadius: '10px' }}
              onClick={() => setViewMode('list')}
            >
              <Icon icon="function_fill" width="20px" height="20px" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Stack mt={2} mb={3}>
          <Typography variant="h5">Plantillas guardadas</Typography>
          <Typography variant="body2" color="text.secondary">
            {templates.total} plantillas
          </Typography>
        </Stack>
        {viewMode === 'list' && (
          <TemplatesListCards
            templates={templates}
            handleLoadMore={handleLoadMore}
            loading={loading}
            onOpenTemplate={onOpenTemplate}
          />
        )}
        {viewMode === 'table' && (
          <TemplatesSimpleTable
            templates={templates}
            page={page}
            perPage={perPage}
            setPage={setPage}
            setPerPage={setPerPage}
            loading={loading}
          />
        )}
      </DialogContent>
      <TemplatePreviewDialog onOpenTemplate={onOpenTemplate} />
    </Dialog>
  );
};
