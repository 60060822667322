import {
  Stack,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import {
  confirmPersonalDataDialogVar,
  resetConfirmPersonalDataDialog,
} from './ConfirmPersonalDataAi.vars';
import { Button, Dialog, Typography } from '../../../../newComponents';
import { ConfirmPersonalDataAiForm } from './ConfirmPersonalDataAiForm';
import { ConfirmPersonalAddressAiForm } from './ConfirmPersonalAddressAiForm';
import { useForm } from 'react-hook-form';
import {
  DEFAULT_ONBOARDING_ADDRESS_DATA,
  DEFAULT_ONBOARDING_NSS_DATA,
  DEFAULT_ONBOARDING_PERSONAL_DATA,
} from './ConfirmPersonalDataAi.constants';
import { ConfirmPersonalNssAiForm } from './ConfirmPersonalNssAiForm';
import { useEffect } from 'react';
import { parseCURP } from '../../../../utils/utils';
import { globalSnackbarVar } from '../../../../cache.reactiveVars';
import { useUpdateUserDataAndUploadPendingDocument } from '../RequestedEmployeeDocumentsHooks/useUpdateUserDataAndUploadPendingDocument';

export const ConfirmPersonalDataAiDialog = () => {
  const { open, formType, imssResponse, satResponse, documentData } =
    useReactiveVar(confirmPersonalDataDialogVar);

  const { handleUpdateUserDataAndUploadPendingDocument } =
    useUpdateUserDataAndUploadPendingDocument();

  const personalDataForm = useForm({
    mode: 'all',
    defaultValues: DEFAULT_ONBOARDING_PERSONAL_DATA,
  });

  const personalAddresForm = useForm({
    mode: 'all',
    defaultValues: DEFAULT_ONBOARDING_ADDRESS_DATA,
  });

  const personalNssForm = useForm({
    mode: 'all',
    defaultValues: DEFAULT_ONBOARDING_NSS_DATA,
  });

  const isValidPersonalData = personalDataForm.formState.isValid;
  const isValidAddress = personalAddresForm.formState.isValid;
  const isValidNss = personalNssForm.formState.isValid;
  const isValid =
    formType === 'PERSONAL_DATA'
      ? isValidPersonalData && isValidAddress
      : isValidNss;

  const handleSubmit = () => {
    if (!isValid) {
      return globalSnackbarVar({
        show: true,
        message: 'Por favor verifica todos los campos',
        severity: 'error',
      });
    }

    handleUpdateUserDataAndUploadPendingDocument({
      dataToUpdate: {
        personalData: personalDataForm,
        addressData: personalAddresForm,
        nssData: personalNssForm,
      },
      documentData: documentData,
      formType,
      onSuccess: () => {
        if (documentData?.onSuccess) documentData.onSuccess();
        resetConfirmPersonalDataDialog();
      },
    });
  };

  useEffect(() => {
    if (satResponse) {
      const scrapingData = satResponse?.webScrapingData;
      const parsedCurp = parseCURP(scrapingData?.CURP);
      personalDataForm.reset({
        birthdate: parsedCurp?.birthdate || null,
        gender: parsedCurp?.gender || '',
        maritalStatus: '',
      });
      personalAddresForm.reset({
        country: 'MEX',
        zipCode: scrapingData?.zipCode || '',
        state: scrapingData?.state || '',
        city: scrapingData?.city || '',
        colony: scrapingData?.colony || '',
        street: scrapingData?.street || '',
        externalNumber: scrapingData?.externalNumber || '',
        internalNumber: scrapingData?.internalNumber || '',
      });
    }
  }, [satResponse, personalDataForm, personalAddresForm]);

  useEffect(() => {
    if (imssResponse) {
      personalNssForm.reset({
        socialSecurityNumber: imssResponse?.nss || '',
      });
    }
  }, [imssResponse, personalNssForm]);

  return (
    <Dialog
      open={open}
      maxWidth={formType === 'PERSONAL_DATA' ? 'md' : 'xs'}
      fullWidth
      slideMode
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        sx={{ padding: '24px' }}
      >
        <Typography variant="h6">Completa tu expediente</Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: '16px' }}>
        <Stack flex="1" spacing={3}>
          {formType === 'PERSONAL_DATA' && (
            <>
              <ConfirmPersonalDataAiForm
                form={personalDataForm}
                onSubmit={() => null}
              />
              <ConfirmPersonalAddressAiForm
                form={personalAddresForm}
                onSubmit={() => null}
              />
            </>
          )}

          {formType === 'NSS' && (
            <ConfirmPersonalNssAiForm
              form={personalNssForm}
              onSubmit={() => null}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={handleSubmit}
          disabled={!isValid}
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
