// @ts-check
import React, { memo } from 'react';
import { Stack, Divider, useTheme, useMediaQuery } from '@mui/material';
import { Typography } from '../../../../../newComponents/Typography';
import { Icon } from '../../../../../components/Icons/Icons';
import { Avatar } from '../../../../../newComponents/Avatar';
import { Label } from '../../../../../newComponents/Label';

export const DocumentChecklistHeaderInfo = memo(
  /** @param {import('./DocumentChecklist.types').DocumentChecklistHeaderInfoProps} props */
  (props) => {
    /** @type {import('../../../../../theme').CustomTheme} */
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { documentChecklistTags } = props;

    return (
      <Stack>
        <Stack
          p={3}
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          gap={2}
        >
          <Stack direction="row" gap={2}>
            {!isSmallScreen && (
              <Avatar
                style={{
                  backgroundColor: theme.newPalette.success.lighter,
                }}
              >
                <Icon
                  icon="checkbox_circle_line"
                  width="24px"
                  height="24px"
                  style={{ color: theme.newPalette.success.main }}
                />
              </Avatar>
            )}
            <Stack>
              <Typography variant="subtitle2">
                Checklist del expediente
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Agrega y ordena los <strong>documentos obligatorios</strong>
              </Typography>
            </Stack>
          </Stack>
          <Stack flex={1} alignItems="flex-end" minWidth="105px">
            <Label
              variant="soft"
              color="success"
              label={`${documentChecklistTags.length} documentos`}
            />
          </Stack>
        </Stack>
        <Divider />
      </Stack>
    );
  },
);
