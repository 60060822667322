import React, { useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { CalendarHeader } from './components/CalendarHeader';
import { SoraCalendar } from '../../../../newComponents/SoraCalendar/SoraCalendar';
import { AddHolidayDialog } from './components/AddHolidayDialog';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { DELETE_HOLIDAY, GET_COMPANY_HOLIDAYS } from './Calendar.gql';
import {
  Typography,
  CircularProgress,
  ConfirmDialog,
} from '../../../../newComponents';
import { CalendarEvent } from '../../../../newComponents/SoraCalendar/SoraCalendar.types';
import {
  globalBackdropVar,
  globalSnackbarVar,
  globalSnackbarVarGenericError,
} from '../../../../cache.reactiveVars';

export const Calendar: React.FC = () => {
  const [isOpenAddHolidayDialog, setIsOpenAddHolidayDialog] = useState(false);
  const [isOpenDeleteHolidayDialog, setIsOpenDeleteHolidayDialog] =
    useState(false);
  const preselectedDate = useRef<null | Date>(null);
  const eventSelected = useRef<null | CalendarEvent>(null);
  const client = useApolloClient();

  const [deleteHolidayMutation] = useMutation(DELETE_HOLIDAY);

  const { data: { getCompanyHolidays: { holidays = [] } = {} } = {}, loading } =
    useQuery(GET_COMPANY_HOLIDAYS, {
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          includeOfficialHolidays: true,
        },
      },
    });
  const mappedHolidays = holidays.map((holiday) => ({
    ...holiday,
    date: new Date(holiday.date),
  }));

  const onClose = () => {
    preselectedDate.current = null;
    eventSelected.current = null;
    setIsOpenAddHolidayDialog(false);
  };
  const onCloseDeleteDialog = () => setIsOpenDeleteHolidayDialog(false);
  const openAddHolidayDialog = (date?: Date) => {
    if (date) {
      preselectedDate.current = date;
    }
    setIsOpenAddHolidayDialog(true);
  };

  const deleteHoliday = async () => {
    if (eventSelected.current) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando día festivo',
      });
      try {
        await deleteHolidayMutation({
          variables: {
            input: {
              holidayId: eventSelected.current._id,
            },
          },
        });
        await client.refetchQueries({
          include: [GET_COMPANY_HOLIDAYS],
        });
        globalSnackbarVar({
          show: true,
          message: 'Día festivo eliminado',
          severity: 'info',
        });
        eventSelected.current = null;
      } catch (error) {
        console.error(error);
        globalSnackbarVarGenericError();
      } finally {
        setIsOpenDeleteHolidayDialog(false);
        globalBackdropVar({
          open: false,
        });
      }
    }
  };

  if (loading)
    return (
      <Stack justifyContent={'center'} alignItems={'center'}>
        <CircularProgress />
      </Stack>
    );
  return (
    <>
      <Stack p={2} gap={2}>
        <CalendarHeader openAddHolidayDialog={openAddHolidayDialog} />
        <SoraCalendar
          handleEmptyDayClick={(date: Date) => {
            openAddHolidayDialog(date);
          }}
          events={mappedHolidays}
          handleDeleteEventClick={(event) => {
            setIsOpenDeleteHolidayDialog(true);
            eventSelected.current = event;
          }}
          handleEventClick={(event) => {
            eventSelected.current = event;
            openAddHolidayDialog();
          }}
        />
      </Stack>
      <AddHolidayDialog
        preselectedDate={preselectedDate?.current}
        preselectedEvent={eventSelected.current}
        open={isOpenAddHolidayDialog}
        onClose={onClose}
        onDeleteHoliday={() => {
          eventSelected.current && setIsOpenDeleteHolidayDialog(true);
          onClose();
        }}
      />
      <ConfirmDialog
        title={'Eliminar día festivo'}
        open={isOpenDeleteHolidayDialog}
        icon={'delete_bin_line'}
        confirmLabel={'Eliminar'}
        ConfirmButtonProps={{
          color: 'error',
        }}
        cancelLabel={'Cancelar'}
        onClose={onCloseDeleteDialog}
        onConfirm={async () => {
          await deleteHoliday();
        }}
        onCancel={onCloseDeleteDialog}
      >
        <Typography>
          Se eliminará el día festivo del calendario de la empresa
        </Typography>
      </ConfirmDialog>
    </>
  );
};
