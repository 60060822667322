import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Typography } from '../../../../../../newComponents';
import { Stack } from '@mui/material';
import { CustomTheme } from '../../../../../../theme';

export const DisplayHolidaysSchemeHeader: React.FC = () => {
  const theme: CustomTheme = useTheme();

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack className={'DisplayHolidaysSchemeHeaderTitles'}>
          <Typography variant={'h4'}>Esquemas de vacaciones</Typography>
          <Typography variant={'body1'} color={theme.newPalette.text.secondary}>
            Los esquemas de vacaciones te ayudan a configurar el tipo de
            vacaciones que se asignan a tus empleados.
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};
