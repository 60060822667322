import { supportDialogVar } from '../../businessComponents/SupportDialog/SupportDialog.vars';

/**
 * @type {import('./NavBar.types').NavBarItems}
 */
export const adminNavBarItems = [
  {
    id: 'dashboard',
    icon: 'bar_chart_2_line',
    activeIcon: 'bar_chart_2_fill',
    label: 'Dashboard',
    link: '/rhpod/admin',
  },
  {
    id: 'employees',
    icon: 'folder_user_line',
    activeIcon: 'folder_user_fill',
    label: 'Empleados',
    link: '/rhpod/admin/empleados',
  },
  {
    id: 'receipts',
    icon: 'money_dollar_circle_line',
    activeIcon: 'money_dollar_circle_fill',
    label: 'Recibos',
    link: '/rhpod/admin/recibos',
  },
  {
    id: 'documents',
    icon: 'file_text_line',
    activeIcon: 'file_text_fill',
    label: 'Documentos',
    link: '/rhpod/admin/documentos',
  },
  {
    id: 'absences',
    icon: 'rest_time_line',
    activeIcon: 'rest_time_fill',
    label: 'Ausencias',
    link: '/rhpod/admin/ausencias',
  },
  {
    id: 'holidays',
    icon: 'sun_foggy_line',
    activeIcon: 'sun_foggy_fill',
    label: 'Vacaciones',
    link: '/rhpod/admin/vacaciones',
    chip: true,
  },
  {
    id: 'credits',
    icon: 'wallet_line',
    activeIcon: 'wallet_fill',
    label: 'Créditos',
    link: '/rhpod/admin/creditos',
  },
  // {
  //   icon: 'bar_chart_box_line',
  //   activeIcon: 'bar_chart_box_line',
  //   label: 'Reportes',
  //   link: '/reportes',
  // },
];

/**
 * @type {import('./NavBar.types').NavBarItems}
 */
export const employeeNavBarItems = [
  {
    id: 'my_home',
    icon: 'home_line',
    activeIcon: 'home_fill',
    label: 'Mi portal',
    link: '/rhpod/empleado',
    tourId: 'my-portal-sm-tour',
  },
  {
    id: 'my_records',
    icon: 'file_edit_line',
    activeIcon: 'file_edit_fill',
    label: 'Mi expediente',
    link: '/rhpod/empleado/documentos',
    tourId: 'my-documents-sm-tour',
  },
  {
    id: 'my_credits',
    icon: 'bank_card_line',
    activeIcon: 'bank_card_fill',
    label: 'Mis créditos',
    link: '/rhpod/empleado/creditos',
    tourId: 'my-credits-sm-tour',
  },
  {
    id: 'my_calendar',
    icon: 'calendar_line',
    activeIcon: 'calendar_fill',
    label: 'Mi calendario',
    link: '/rhpod/empleado/calendario',
    tourId: 'my-calendar-sm-tour',
    chip: true,
  },
];

/**
 * @type {import('./NavBar.types').NavBarItems}
 */
export const othersNavBarItems = [
  {
    id: 'settings',
    icon: 'settings_3_line',
    activeIcon: 'settings_3_fill',
    label: 'Configuración',
    link: '/ajustes/mi-empresa',
  },
  {
    id: 'support',
    icon: 'headphone_line',
    activeIcon: 'headphone_fill',
    label: 'Soporte',
    tourId: 'support-contact-tour',
    action: () => {
      supportDialogVar({ open: true });
    },
  },
];
