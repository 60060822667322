import React from 'react';
import { Box, Stack, TooltipProps, useTheme } from '@mui/material';
import { Icon } from '../../components/Icons/Icons';
import { useReactiveVar } from '@apollo/client';
import { useCompanyModules } from '../../Hooks/useCompanyModules';
import { employeeRecordTagsAiVar } from '../../containers/Settings/Preferences/EmployeeRecordTagsCatalog/EmployeeRecordTagsCatalog.vars';
import { getActiveAiByCategory } from '../../containers/Settings/Preferences/EmployeeRecordTagsCatalog/EmployeeRecordTagsCatalog.helpers';
import { Tooltip } from '../Tooltip';
import { Typography } from '../Typography';
import { useScreenSize } from '../../Hooks';

type AiTagProps = Pick<TooltipProps, 'placement'> & {
  categoryId: string;
  subcategoryId: string;
  type?: string;
  verified?: boolean;
  errorMessage?: string;
};

const TOOLTIP_MAP = {
  ADMIN_UPLOAD_DOC:
    'Si el admin carga el documento, este no será analizado con IA',
  DISABLED_MODULE: 'Módulo IA desactivado. Contacte a ventas para activarlo',
  SUCCESS: 'Este documento será analizado con IA',
};

const getCurrentType = ({ disabled, type }) => {
  if (!disabled) return 'DISABLED_MODULE';
  if (type === 'ADMIN_UPLOAD_DOC') return 'ADMIN_UPLOAD_DOC';
  return 'SUCCESS';
};

export const AiTag: React.FC<AiTagProps> = ({
  placement,
  categoryId,
  subcategoryId,
  type,
  verified,
  errorMessage = '',
}) => {
  const theme = useTheme();
  const tags = useReactiveVar(employeeRecordTagsAiVar);
  const { isMobile } = useScreenSize();
  const { ai } = useCompanyModules();

  const category = tags?.find(
    (item) =>
      item?._id === categoryId ||
      item?.category?.toLowerCase() === categoryId?.toLowerCase(),
  );

  const subCategories = category?.subcategories ?? [];
  const subcategoryFound = subCategories?.find(
    (i) =>
      i?._id === subcategoryId ||
      i?.subcategory?.toLowerCase() === subcategoryId?.toLowerCase(),
  );

  if (!subcategoryFound || !category) return null;

  let { isTagDisabledByModuleConfiguration } = getActiveAiByCategory({
    ai: ai,
    categoryName: category.category,
    subcategoryName: subcategoryFound.subcategory,
  });

  const currentType = getCurrentType({
    disabled: isTagDisabledByModuleConfiguration,
    type,
  });

  const tooltip = TOOLTIP_MAP[currentType];

  if (
    currentType === 'ADMIN_UPLOAD_DOC' &&
    isTagDisabledByModuleConfiguration
  ) {
    isTagDisabledByModuleConfiguration = false;
  }

  if (!subcategoryFound?.isAiEnabled) return null;

  return (
    <Tooltip
      title={verified ? errorMessage : tooltip}
      placement={placement ?? 'right'}
    >
      <Box sx={{ width: verified && !isMobile ? '120px' : '50px' }}>
        <Stack
          direction="row"
          spacing="5px"
          sx={{
            background: !isTagDisabledByModuleConfiguration
              ? theme.newPalette.primary.gradient
              : undefined,
            opacity: !isTagDisabledByModuleConfiguration ? 0.5 : 1,
            backgroundImage: isTagDisabledByModuleConfiguration
              ? theme.newPalette.primary.gradient
              : undefined,
            borderRadius: 2,
            color: theme.newPalette.common.white,
            padding: '2px 4px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon icon="bard_fill" width="14px" height="14px" />
          <Typography
            variant="body2"
            color={theme.newPalette.common.white}
            fontWeight="bold"
            fontSize="11px"
          >
            {verified && !isMobile ? 'Analizado con IA' : 'IA'}
          </Typography>
        </Stack>
      </Box>
    </Tooltip>
  );
};
