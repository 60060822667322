// @ts-check
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { bgColorsMap, styles } from './NavBarItem.styles';
import { Icon } from '../../../components/Icons/Icons';
import {
  Typography,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '../../../newComponents';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { NavBarItemProps } from '../NavBar.types';

type ChipProps = React.PropsWithChildren<{ expanded?: boolean }>;
const Chip: React.FC<ChipProps> = ({ children, expanded }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: theme.newPalette.primary.light,
        color: 'white',
        borderRadius: '12px',
        padding: '2px 4px',
        fontSize: '8px',
        fontWeight: 'bold',
        transform: {
          sm: 'none',
          md: expanded ? 'none' : 'translate(4px, -4px)',
        },
      }}
    >
      {children}
    </Box>
  );
};

export const NavBarItem: React.FC<NavBarItemProps> = ({
  item,
  expanded,
  activeColor = 'text',
  type = 'OTHER',
  id = '',
  chip,
}) => {
  const { label, link, icon, activeIcon, action = () => {} } = item;
  const style = styles[expanded ? 'expanded' : 'mini'];
  const location = useLocation();
  const isSelected = location.pathname === link;
  const iconToUse = isSelected ? activeIcon : icon;
  const colorToUse = isSelected ? activeColor : 'grey';
  const bgColorToUse = isSelected ? bgColorsMap[type] : 'none';
  const componentToUse = link ? Link : 'div';

  return (
    <ListItemButton
      component={componentToUse}
      sx={[style.button, { backgroundColor: bgColorToUse + '!important' }]}
      selected={isSelected}
      onClick={action}
      id={id}
      {...(link ? { to: link } : {})}
    >
      <ListItemIcon sx={style.listItemIcon}>
        {chip && <Chip expanded={expanded}>BETA</Chip>}
        <Icon sx={style.icon} icon={iconToUse} color={colorToUse} />
      </ListItemIcon>
      <ListItemText sx={style.listItemText}>
        <Typography sx={style.text} color={colorToUse}>
          {label}
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};
