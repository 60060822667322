import { useMutation, useReactiveVar } from '@apollo/client';
import { VERIFY_PENDING_REQUESTED_EMP_DOC_WITH_AI } from '../RequestedEmployeeDocumentsDialog.gql';
import {
  currentCompanyVar,
  globalBackdropVar,
  globalSnackbarVar,
  userVar,
} from '../../../../cache.reactiveVars';
import {
  ERROR_IMSS_RESPONSE,
  ERROR_SAT_RESPONSE,
} from '../ConfirmPersonalData/ConfirmPersonalDataAi.constants';
import { ErrorAiResponseType } from '../ConfirmPersonalData/ConfirmPersonalDataAi.types';

export type OnSuccessType = {
  satResponse?: any;
  imssResponse?: any;
  error: ErrorAiResponseType;
};

export type VerifyPendingDocumentWithAiInput = {
  file: File;
  categoryId: string;
  subcategoryId: string;
  onSuccess?: (response: OnSuccessType) => void;
  onLimitExceeded: () => void;
};

export const useVerifyPendingDocumentWithAi = () => {
  const company = useReactiveVar(currentCompanyVar);
  const user = useReactiveVar(userVar);
  const [verifyPendingDocument] = useMutation(
    VERIFY_PENDING_REQUESTED_EMP_DOC_WITH_AI,
  );

  const handleVerifyPendingDocumentWithAi = async (
    input: VerifyPendingDocumentWithAiInput,
  ) => {
    const successMessage = 'Achivo verificado con éxito';
    const {
      file: acceptedFile,
      categoryId,
      subcategoryId,
      onSuccess,
      onLimitExceeded,
    } = input;

    try {
      globalBackdropVar({ open: true, text: 'Revisando archivo con IA...' });

      const response = await verifyPendingDocument({
        variables: {
          file: acceptedFile,
          input: {
            companyId: company?._id,
            userId: user?._id,
            categoryId,
            subcategoryId,
          },
        },
      });

      const data = response.data?.verifyPendingDocumentWithAi;

      if (data?.__typename === 'ErrorToVerifyPendingDocumentWithAi') {
        globalSnackbarVar({
          show: true,
          message: data.message || 'Ocurrió un error al validar documento',
          severity: 'error',
        });
        return;
      }

      if (data?.__typename === 'LimitExceededError') {
        onLimitExceeded && onLimitExceeded();
        return;
      }

      const validatedInfo = data?.imssResponse || data?.satResponse;
      const isSatResponse = Boolean(data?.satResponse);
      const errors = isSatResponse ? ERROR_SAT_RESPONSE : ERROR_IMSS_RESPONSE;
      const responseType = validatedInfo?.type;
      if (responseType === 'SUCCESS') {
        onSuccess && onSuccess(data);
        globalSnackbarVar({
          show: true,
          message: successMessage,
          severity: 'success',
        });
        return;
      }

      if (errors[responseType]) {
        globalSnackbarVar({
          show: true,
          message: errors[responseType],
          severity: 'error',
        });

        if (onSuccess) {
          onSuccess({
            error: { message: errors[responseType], type: responseType },
          });
        }
      }
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al verificar documento con IA',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  return { handleVerifyPendingDocumentWithAi };
};
