import {
  CSF_SUBCATEGORY,
  NSS_SUBCATEGORY,
  PERSONAL_CATEGORY,
} from './EmployeeRecordTagsCatalog.constants';
import { EmployeeRecordTagCategory } from './EmployeeRecordTagsCatalog.types';

export const filterAbsenceCategory = (
  employeeRecordTags: EmployeeRecordTagCategory[],
) => {
  if (!employeeRecordTags) return [];
  return employeeRecordTags.filter((item) => item.category !== 'Ausencias');
};

type BasicModule = {
  active: boolean;
};

export type AiModule = BasicModule & {
  getTaxCertificateData: boolean;
  getImssCertificateData: boolean;
};

type ActiveAiInput = {
  ai: AiModule;
  categoryName: string;
  subcategoryName: string;
  isDragging?: boolean;
};

export const getActiveAiByCategory = (input: ActiveAiInput) => {
  const { ai, categoryName, subcategoryName, isDragging } = input;

  const isPersonalCategory = categoryName === PERSONAL_CATEGORY;
  const isNssSubcategory = subcategoryName === NSS_SUBCATEGORY;
  const isCsfSubcategory = subcategoryName === CSF_SUBCATEGORY;

  const canShow = isPersonalCategory && (isNssSubcategory || isCsfSubcategory);

  if (!ai?.active) {
    return {
      show: canShow,
      isTagDisabledByModuleConfiguration: false,
    };
  }

  //When is dragging and subcategory exists, categoryName is undefined
  if (isDragging && subcategoryName === NSS_SUBCATEGORY) {
    return {
      show: true,
      isTagDisabledByModuleConfiguration: ai?.getImssCertificateData,
    };
  }

  if (isDragging && subcategoryName === CSF_SUBCATEGORY) {
    return {
      show: true,
      isTagDisabledByModuleConfiguration: ai?.getTaxCertificateData,
    };
  }

  if (categoryName !== PERSONAL_CATEGORY) {
    return {
      show: canShow,
      isTagDisabledByModuleConfiguration: false,
    };
  }

  if (subcategoryName === NSS_SUBCATEGORY) {
    return {
      show: canShow,
      isTagDisabledByModuleConfiguration: ai?.getImssCertificateData,
    };
  }

  if (subcategoryName === CSF_SUBCATEGORY) {
    return {
      show: canShow,
      isTagDisabledByModuleConfiguration: ai?.getTaxCertificateData,
    };
  }

  return {
    show: canShow,
    isTagDisabledByModuleConfiguration: false,
  };
};
