// @ts-check
import { Stack, useTheme } from '@mui/material';
import { ControlledSwitch } from '../../../../components/ControlledInputs/ControlledSwitch/ControlledSwitch';
import { BodyMedium, H6 } from '../../../../components/Typographies/Typography';
import React, { useEffect, useState } from 'react';
import { ValiditySelector } from './ValiditySelector';

/**
 * @typedef {import('react-hook-form').FormProviderProps} ReactHookForm
 * @typedef {import('@mui/material').Theme} MaterialTheme
 * @typedef {import('../../../../theme').CustomTheme} CustomTheme
 *
 * @param {object} props
 * @param {ReactHookForm} props.form
 * @param {string} props.docType
 * @deprecated
 */
export const DocumentSignatureSettings = ({ form, docType }) => {
  /** @type {CustomTheme} */
  const theme = useTheme();
  const watchSignable = form.watch('signable');
  /** @type {[Date|null, React.Dispatch<React.SetStateAction<Date|null>>]} */
  const [validity, setValidity] = useState(null);

  const callBackFunc = (isSignable) => {
    if (!isSignable) {
      form.setValue('hideable', false);
      form.setValue('highPriority', false);
    }
  };

  useEffect(() => {
    form.setValue('validity', validity);
  }, [form, validity]);

  return (
    <Stack
      padding={3}
      borderRadius={2}
      boxShadow="0 4px 8px 0 rgba(65, 67, 91, 0.2), 0 2px 4px 0 rgba(65, 67, 91, 0.2)"
      spacing={3}
    >
      {docType !== 'creator' && (
        <>
          <H6 color={theme.customPalette.primary.blue}>
            Configuracion del documento
          </H6>
          <BodyMedium color={theme.customPalette.primary.blue}>
            Opciones de firmado
          </BodyMedium>
        </>
      )}
      <ValiditySelector value={validity} onChange={setValidity} />
      <ControlledSwitch
        control={form.control}
        name="signable"
        description="Al activar esta opción este documento deberá firmarse por los destinatarios."
        muiProps={{
          labelProps: {
            label: 'Documento firmable',
            labelPlacement: 'start',
            sx: { marginLeft: 0, justifyContent: 'space-between' },
          },
        }}
        callBack={callBackFunc}
      />
      <ControlledSwitch
        control={form.control}
        name="hideable"
        description="Al activar esta opción este documento se ocultará únicamente al empleado."
        muiProps={{
          switchProps: {
            disabled: !watchSignable,
          },
          labelProps: {
            label: 'Ocultar tras firma del empleado',
            labelPlacement: 'start',
            sx: { marginLeft: 0, justifyContent: 'space-between' },
          },
        }}
      />
      <ControlledSwitch
        control={form.control}
        name="highPriority"
        description="Este documento aparecerá tras inicio de sesión del empleado y solo podrá continuar hasta que lo haya firmado."
        muiProps={{
          switchProps: {
            disabled: !watchSignable,
          },
          labelProps: {
            label: 'Documento de alta prioridad',
            labelPlacement: 'start',
            sx: { marginLeft: 0, justifyContent: 'space-between' },
          },
        }}
      />
    </Stack>
  );
};
