import { useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { DOCUMENT_STATUS } from '../RequestedEmployeeDocumentDialog.constants';
import { GET_REQUESTED_EMPLOYEE_DOCUMENTS } from '../RequestedEmployeeDocumentsDialog.gql';
import {
  requestedEmployeeDocumentsDialogVar,
  setRequestedEmployeeDocumentsDialogVar,
} from '../RequestedEmployeeDocumentsDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';
import { useLocation } from 'react-router-dom';
import {
  GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS,
  MY_DOCUMENTS,
} from '../../../../containers/RHPod/EmployeePortal/gql';

export const useGetRequestedEmployeeDocuments = () => {
  const location = useLocation();
  const client = useApolloClient();

  const refetchDocuments = async () => {
    const queries = [];

    if (location.pathname === '/rhpod/empleado') {
      queries.push(GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS);
    }

    if (location.pathname === '/rhpod/empleado/documentos') {
      queries.push(MY_DOCUMENTS);
    }

    if (!queries.length) return;

    await client.refetchQueries({ include: queries });
  };

  const { loading, data } = useQuery(GET_REQUESTED_EMPLOYEE_DOCUMENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al cargar documentos',
        severity: 'error',
      });
    },
  });

  useEffect(() => {
    if (loading) {
      globalBackdropVar({ open: true, text: 'Cargando documentos...' });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  useEffect(() => {
    const requestedEmployeeDocuments =
      data?.getRequestedEmployeeDocuments || [];

    setRequestedEmployeeDocumentsDialogVar({ requestedEmployeeDocuments });

    if (!requestedEmployeeDocuments.length) return;

    const thereAreRejectedOrNotUploadedDocuments =
      requestedEmployeeDocuments.some(
        (document) =>
          document.status === DOCUMENT_STATUS.REJECTED ||
          document.status === DOCUMENT_STATUS.NOT_UPLOADED,
      );
    if (!thereAreRejectedOrNotUploadedDocuments) {
      const { onFinish } = requestedEmployeeDocumentsDialogVar();
      onFinish();
      refetchDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};
