//@ts-check
import React, { useCallback, useEffect, useState } from 'react';
import * as rdrLocales from 'react-date-range/dist/locale';
import { Calendar as RDRCalendar } from 'react-date-range';
import { format } from 'date-fns';
import './calendarStyle.css';
import {
  Dialog,
  InputAdornment,
  Menu,
  Stack,
  useMediaQuery,
} from '@mui/material';

import { TextInput } from '../TextInput';
import { Button } from '../Button';
import { Icon } from '../../components/Icons/Icons';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { esES } from '@mui/x-date-pickers/locales';
import { es } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTheme } from '@mui/material/styles';
import { globalSnackbarVar } from '../../cache.reactiveVars';

/**
 * @typedef {import('@mui/material').TextFieldProps} TextFieldProps
 * @typedef {import('react-date-range').CalendarProps} CalendarProps
 */

/**
 * @param {object} props
 * @param {Date | null} [props.date]
 * @param {(date: Date | null) => void} [props.setDate]
 * @param {string} [props.formatDate='EEE d MMM yy']
 * @param {TextFieldProps['size']} [props.size]
 * @param {string} [props.label = '']
 * @param {boolean} [props.error = false]
 * @param {string} [props.helperText = '']
 * @param {boolean} [props.showIconToRemove = false]
 * @param {boolean} [props.showStartCalendarIcon = false]
 * @param {boolean} [props.showEndCalendarIcon = false]
 * @param {Date} [props.minDate]
 * @param {Date} [props.maxDate]
 * @param {TextFieldProps} [props.textFieldProps]
 * @param {CalendarProps} [props.calendarProps]
 * @param {boolean} [props.includeTime = false]
 * @param {string} [props.undefinedLabel]
 */
export const Calendar = ({
  date,
  setDate = () => {},
  formatDate = 'EEE d MMM yy',
  size = 'medium',
  label = '',
  error = false,
  helperText = '',
  showIconToRemove = false,
  showStartCalendarIcon = false,
  showEndCalendarIcon = false,
  textFieldProps = {},
  calendarProps = {},
  minDate,
  maxDate,
  includeTime = false,
  undefinedLabel,
}) => {
  /** @type {import("../../theme").CustomTheme} */
  const theme = useTheme();
  const palette = theme.newPalette;
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateInput, setDateInputValue] = useState('');
  const open = Boolean(anchorEl);

  //time used for the time picker
  const [/** @type {Date} */ timeDate, setTimeDate] = useState(date ?? null);
  //time used for the calendar picker
  const [/** @type {Date} */ calendarDate, setCalendarDate] = useState(
    date ?? null,
  );
  //time used for the component
  const [/** @type {Date} */ globalDate, setGlobalDate] = useState(
    date ?? null,
  );

  const handleOpen = (event) => {
    if (mobile) {
      return setOpenDialog(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  /**
   * Parse date to readable human string
   * @type {(function(Date): string)}
   */
  const getStringFormattedDate = useCallback(
    (date) => {
      if (!date) return '';

      return format(date, formatDate, {
        locale: rdrLocales.es,
      });
    },
    [formatDate],
  );

  const updateInputStringValue = useCallback(() => {
    const dateString = getStringFormattedDate(date);
    setDateInputValue(dateString);
  }, [date, getStringFormattedDate]);

  /**
   * @param {object} input
   * @param {number} [input.year]
   * @param {number} [input.month]
   * @param {number} [input.day]
   * @param {number} [input.hour]
   * @param {number} [input.seconds]
   * @returns {boolean}
   */
  const updateGlobalDate = ({ year, month, day, hour, seconds }) => {
    const dateToSet = new Date(globalDate);
    if (year !== undefined) dateToSet.setFullYear(year);
    if (month !== undefined) dateToSet.setMonth(month);
    if (day !== undefined) dateToSet.setDate(day);
    if (hour !== undefined) dateToSet.setHours(hour);
    if (seconds !== undefined) dateToSet.setMinutes(seconds);

    if (minDate && dateToSet < minDate) {
      globalSnackbarVar({
        show: true,
        message: 'La fecha no puede ser anterior a la fecha actual',
        severity: 'error',
      });
      return false;
    }
    setGlobalDate(dateToSet);
    setDate(dateToSet);
  };

  const handleChangeCalendarDate = (/** @type {Date} */ date) => {
    let dateToSet = new Date(date);
    setCalendarDate(dateToSet);
    updateGlobalDate({
      year: dateToSet.getFullYear(),
      month: dateToSet.getMonth(),
      day: dateToSet.getDate(),
    });
    if (!includeTime) {
      handleClose();
    }
  };

  const onChangeTime = (time) => {
    setTimeDate(time);
    updateGlobalDate({
      hour: time.getHours(),
      seconds: time.getMinutes(),
    });
  };

  const handleAccept = () => {
    handleCloseDialog();
  };
  const handleCancel = () => {
    handleCloseDialog();
  };

  const handleClean = () => {
    setDate(null);
    setDateInputValue(undefinedLabel ?? '');
  };

  useEffect(() => {
    const dateToSet = date ? new Date(date) : new Date();
    setTimeDate(dateToSet);
    setCalendarDate(dateToSet);
    setGlobalDate(dateToSet);
    updateInputStringValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const calendarComponent = (
    <RDRCalendar
      onChange={handleChangeCalendarDate}
      // @ts-ignore
      date={calendarDate}
      locale={rdrLocales.es}
      fixedHeight
      weekStartsOn={0}
      showSelectionPreview={false}
      minDate={minDate ? new Date(minDate) : undefined}
      maxDate={maxDate ? new Date(maxDate) : undefined}
      {...calendarProps}
    />
  );

  const timeComponent = includeTime ? (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      localeText={
        esES.components.MuiLocalizationProvider.defaultProps.localeText
      }
      adapterLocale={es}
    >
      <TimePicker
        label="hora"
        onAccept={onChangeTime}
        onChange={onChangeTime}
        value={timeDate}
      />
    </LocalizationProvider>
  ) : null;

  return (
    <>
      <TextInput
        placeholder="Fecha"
        label={label}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}
        value={dateInput}
        size={size}
        error={error}
        helperText={helperText}
        {...textFieldProps}
        InputProps={{
          readOnly: true,
          sx: {
            cursor: 'pointer!important',
            '& .MuiOutlinedInput-input': {
              cursor: 'pointer!important',
            },
            '& .Mui-disabled': {
              cursor: 'not-allowed!important',
              color: 'black',
            },
          },
          ...(showStartCalendarIcon && {
            startAdornment: (
              <InputAdornment position="start" disablePointerEvents>
                <Icon icon="calendar_line" />
              </InputAdornment>
            ),
          }),
          ...(showEndCalendarIcon && {
            endAdornment: (
              <InputAdornment position="end" disablePointerEvents>
                <Icon icon="calendar_line" />
              </InputAdornment>
            ),
          }),
          ...(showIconToRemove &&
            !Boolean(textFieldProps.disabled) && {
              endAdornment: (
                <InputAdornment
                  sx={{ cursor: 'pointer' }}
                  position="end"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClean();
                  }}
                >
                  <Icon icon="close_line" />
                </InputAdornment>
              ),
            }),
        }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              width: '320px',
              borderRadius: '16px',
              boxShadow: palette.shadow.dropdown,
            },
          },
        }}
      >
        <Stack>
          {calendarComponent}
          {timeComponent}
        </Stack>
      </Menu>
      <Dialog
        maxWidth="xs"
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          sx: {
            mt: 1,
            width: '320px',
            borderRadius: '16px',
            boxShadow: palette.shadow.dropdown,
          },
        }}
        sx={{
          borderRadius: '10px',
        }}
      >
        <Stack minHeight="300px" alignItems="space-between">
          <Stack>
            {calendarComponent}
            {timeComponent}
          </Stack>
          <Stack justifyContent="flex-end" flexDirection="row" mt={10}>
            <Button onClick={handleAccept}>Cancelar</Button>
            <Button onClick={handleCancel}>OK</Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
