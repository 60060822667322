import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_EMPLOYEE_RECORDS_TAGS } from '../EmployeeRecordTagsCatalog.gql';
import { EmployeeRecordTagCategory } from '../EmployeeRecordTagsCatalog.types';
import { employeeRecordTagsVar } from '../EmployeeRecordTagsCatalog.vars';
import {
  globalBackdropVar,
  globalSnackbarVarGenericError,
} from '../../../../../cache.reactiveVars';

export const useEmployeeRecordTags = () => {
  const [employeeRecordTags, setEmployeeRecordTags] = useState<
    EmployeeRecordTagCategory[]
  >([]);

  const { loading } = useQuery(GET_EMPLOYEE_RECORDS_TAGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ getEmployeeRecordsTags }) => {
      const employeeRecordTags = getEmployeeRecordsTags;
      setEmployeeRecordTags(employeeRecordTags);
      employeeRecordTagsVar(employeeRecordTags);
    },
    onError: () => {
      globalSnackbarVarGenericError();
    },
  });

  useEffect(() => {
    if (loading) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Cargando etiquetas...',
      });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading]);

  return {
    employeeRecordTags,
  };
};
