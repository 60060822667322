import { useEffect, useState } from 'react';
import {
  CardHeader,
  CardContent,
  CardActions,
  Box,
  Card,
  useTheme,
  Stack,
} from '@mui/material';
import { Button } from '../../../../newComponents/Button';
import { Typography } from '../../../../newComponents/Typography';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { IconButton } from '../../../../newComponents/IconButton';
import { Label } from '../../../../newComponents/Label';
import { Upload } from '../../../../newComponents/Upload/Upload';
import { mimeTypes } from '../../../../utils/constants';
import { bytesToMB } from '../../../../utils/utils';
import { DocumentHistoryButton } from '../../../DocumentHistory';
import { useUploadRequestedEmployeeDocument } from '../RequestedEmployeeDocumentsHooks/useUploadRequestedEmployeeDocument';
import { Icon } from '../../../../components/Icons/Icons';
import { DOCUMENT_STATUS } from '../RequestedEmployeeDocumentDialog.constants';
import type { RequestedEmployeeDocumentCardProps } from '../RequestedEmployeeDocumentsDialog.types';
import type { FileRejection } from '../../../../newComponents/Upload/FileUpload.types';
import {
  AcceptedFileWrapper,
  RejectedFileWrapper,
} from './RequestedEmployeeDocumentCard.styles';
import { AiTag } from '../../../../newComponents/AiTag/AiTag';
import { useVerifyPendingDocumentWithAi } from '../RequestedEmployeeDocumentsHooks/useVerifyPendingDocumentWithAi';
import { useCompanyModules } from '../../../../Hooks/useCompanyModules';
import { useGetActiveAi } from '../../../../Hooks/useGetActiveAi';
import {
  confirmPersonalDataDialogVar,
  setConfirmPersonalDataDialogVar,
} from '../ConfirmPersonalData/ConfirmPersonalDataAi.vars';
import { NSS_SUBCATEGORY } from '../../../../containers/Settings/Preferences/EmployeeRecordTagsCatalog/EmployeeRecordTagsCatalog.constants';
import { useReactiveVar } from '@apollo/client';
import type { ErrorAiResponseType } from '../ConfirmPersonalData/ConfirmPersonalDataAi.types';

export const RequestedEmployeeDocumentCard = (
  props: RequestedEmployeeDocumentCardProps,
) => {
  const theme = useTheme();
  const { handleUploadPendingDocument } = useUploadRequestedEmployeeDocument();
  const { handleVerifyPendingDocumentWithAi } =
    useVerifyPendingDocumentWithAi();
  const { error } = useReactiveVar(confirmPersonalDataDialogVar);
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null);
  const [rejectedFile, setRejectedFile] = useState<FileRejection | null>(null);
  const [uploadWithLimitExceeded, setUploadWithLimitExceeded] = useState(false);
  const [localError, setLocalError] = useState<ErrorAiResponseType>(null);
  const [limitExceeded, setLimitExceeded] = useState<ErrorAiResponseType>(null);
  const { requestedDocument } = props;
  const { _id, category, subcategory, status, history } = requestedDocument;
  const { ai } = useCompanyModules();
  const isAiEnabled = useGetActiveAi({
    categoryId: category,
    subcategoryId: subcategory,
    ai,
  });

  const UPLOAD_DOCUMENT_PROPS = {
    _id,
    file: acceptedFile,
    category,
    subcategory,
    onSuccess: () => {
      setAcceptedFile(null);
      setRejectedFile(null);
      setLimitExceeded(null);
    },
  };

  const handleUploadWithOutAi = async () => {
    await handleUploadPendingDocument({
      ...UPLOAD_DOCUMENT_PROPS,
      adminUploadDocument: false,
      responseAi: error ? error : limitExceeded,
    });
  };

  const onSuccessVerifiedFileAi = (response) => {
    const formType = subcategory === NSS_SUBCATEGORY ? 'NSS' : 'PERSONAL_DATA';
    if (response?.error) {
      setConfirmPersonalDataDialogVar({ error: response?.error });
      setRejectedFile({
        file: acceptedFile,
        errors: [
          {
            code: 'ai_error',
            message: response?.error?.message || '',
          },
        ],
      });
      setLocalError(response?.error);
      return;
    }

    setConfirmPersonalDataDialogVar({
      open: true,
      formType: formType,
      imssResponse: response?.imssResponse,
      satResponse: response?.satResponse,
      documentData: UPLOAD_DOCUMENT_PROPS,
    });
  };

  const handleSubmit = async () => {
    if (isAiEnabled) {
      await handleVerifyPendingDocumentWithAi({
        categoryId: category,
        subcategoryId: subcategory,
        file: acceptedFile,
        onSuccess: (response) => {
          onSuccessVerifiedFileAi(response);
        },
        onLimitExceeded: () => {
          setUploadWithLimitExceeded(true);
          setLimitExceeded({
            type: 'LIMIT_AI_EXCEEDED',
            message: 'Se ha excedido el límite de verificaciones de IA',
          });
        },
      });
      return;
    }

    await handleUploadWithOutAi();
  };

  useEffect(() => {
    if (uploadWithLimitExceeded && acceptedFile) {
      setUploadWithLimitExceeded(false);
      handleUploadWithOutAi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadWithLimitExceeded]);

  const getStatusLabel = () => {
    switch (status) {
      case DOCUMENT_STATUS.PENDING_REVIEW: {
        return (
          <Tooltip
            title={requestedDocument?.aiResponse?.message || ''}
            placement="right"
          >
            <span>
              <Label
                startIcon="eye_line"
                variant="soft"
                color="warning"
                label="Esperando revisión"
                aria-label="Esperando revisión"
              />
            </span>
          </Tooltip>
        );
      }
      case DOCUMENT_STATUS.APPROVED: {
        return (
          <Label
            startIcon="checkbox_circle_line"
            variant="soft"
            color="success"
            label="Aprobado"
            aria-label="Aprobado"
          />
        );
      }
      case DOCUMENT_STATUS.NOT_UPLOADED: {
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            width="100%"
          >
            <AiTag
              categoryId={category}
              subcategoryId={subcategory}
              verified={Boolean(localError)}
              errorMessage={localError?.message}
            />
            <Label
              startIcon="time_line"
              variant="soft"
              color="default"
              label="Pendiente de enviar"
              aria-label="Pendiente de enviar"
            />
          </Stack>
        );
      }
      case DOCUMENT_STATUS.REJECTED: {
        const lastRejectedHistory = [...history]
          .reverse()
          .find((event) => event.action === 'REJECTED');
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
            width="100%"
          >
            <AiTag categoryId={category} subcategoryId={subcategory} />
            <Tooltip
              title={`Motivo: ${lastRejectedHistory?.comment || 'Desconocido'}`}
              placement="right"
            >
              <span>
                <Label
                  startIcon="close_circle_line"
                  variant="soft"
                  color="error"
                  label="Rechazado"
                  aria-label="Rechazado"
                />
              </span>
            </Tooltip>
          </Stack>
        );
      }
      default:
        return null;
    }
  };

  const handleFileChange = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    if (fileRejections.length > 0) {
      setRejectedFile(fileRejections[0]);
      setAcceptedFile(null);
    } else if (acceptedFiles.length > 0) {
      setAcceptedFile(acceptedFiles[0]);
      setRejectedFile(null);
    }
  };

  const renderFilePreview = () => {
    if (rejectedFile) {
      return (
        <RejectedFileWrapper
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setRejectedFile(null);
            setAcceptedFile(null);
            setLocalError(null);
          }}
        >
          <Typography
            variant="subtitle1"
            textAlign="center"
            color={theme.newPalette.error.main}
            style={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
              overflow: 'hidden',
            }}
          >
            {rejectedFile.file.name}
          </Typography>
          <Typography
            variant="caption"
            textAlign="center"
            color={theme.newPalette.error.main}
            style={{
              wordBreak: 'break-word',
            }}
          >
            {rejectedFile.errors.map((error) => error.message).join(' - ')}
          </Typography>
        </RejectedFileWrapper>
      );
    }

    if (acceptedFile) {
      return (
        <AcceptedFileWrapper>
          <IconButton
            icon="close_circle_line"
            color="error"
            onClick={() => setAcceptedFile(null)}
            size="small"
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
          />
          <Typography
            variant="subtitle1"
            color={theme.newPalette.text.disabled}
            textAlign="center"
            sx={{ overflowWrap: 'break-word', width: '100%' }}
          >
            {acceptedFile.name}
          </Typography>
          <Typography variant="caption" color={theme.newPalette.text.disabled}>
            {bytesToMB(acceptedFile.size)} MB
          </Typography>
        </AcceptedFileWrapper>
      );
    }

    return null;
  };

  const shouldUploadDocument =
    status === DOCUMENT_STATUS.REJECTED ||
    status === DOCUMENT_STATUS.NOT_UPLOADED;

  return (
    <Card
      variant="outlined"
      sx={{ borderRadius: '16px', padding: '24px' }}
      data-testid="requested-employee-document-card"
    >
      <CardHeader
        sx={{ padding: '0' }}
        avatar={<DocumentHistoryButton document={requestedDocument} />}
        title={<Typography variant="h6">{subcategory}</Typography>}
        subheader={<Box display="inline-block">{getStatusLabel()}</Box>}
      />
      {shouldUploadDocument && (
        <CardContent sx={{ padding: '20px 0px' }}>
          {renderFilePreview()}
          {!rejectedFile && !acceptedFile && (
            <Upload
              variant="blockFile"
              orientation="column"
              title="Carga o arrastra tu archivo aquí"
              iconSize="40px"
              accept={[...mimeTypes.image, mimeTypes.pdf]}
              onChange={handleFileChange}
              sx={{ width: '100%' }}
            />
          )}
        </CardContent>
      )}
      {shouldUploadDocument && (
        <CardActions sx={{ justifyContent: 'flex-end', padding: '0' }}>
          <Button
            variant="contained"
            endIcon={<Icon icon="send_plane_line" />}
            disabled={!acceptedFile || Boolean(rejectedFile)}
            onClick={() => {
              return handleSubmit();
            }}
          >
            Enviar a revisión
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
