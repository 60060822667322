import gql from 'graphql-tag';

export const GET_EMPLOYEE_RECORDS_TAGS = gql`
  query GetEmployeeRecordsTags {
    getEmployeeRecordsTags {
      _id
      category
      description
      color
      isSoraCategory
      subcategories {
        _id
        subcategory
        isSoraSubcategory
        isAiEnabled
        description
      }
    }
  }
`;

export const CREATE_EMPLOYEE_RECORD_TAG_CATEGORY = gql`
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      ... on Success {
        message
        warnings
      }
      ... on ErrorCreateCategory {
        message
      }
    }
  }
`;

export const CREATE_EMPLOYEE_RECORD_TAG_SUBCATEGORY = gql`
  mutation CreateSubcategory($input: CreateSubcategoryInput!) {
    createSubcategory(input: $input) {
      ... on Success {
        message
        warnings
      }
      ... on ErrorCreateSubcategory {
        message
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_RECORD_TAG_CATEGORY = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      ... on Success {
        message
        warnings
      }
      ... on ErrorUpdateCategory {
        message
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_RECORD_TAG_SUBCATEGORY = gql`
  mutation UpdateSubcategory($input: UpdateSubcategoryInput!) {
    updateSubcategory(input: $input) {
      ... on Success {
        message
      }
      ... on ErrorUpdateSubcategory {
        message
      }
    }
  }
`;

export const DELETE_EMPLOYEE_RECORD_TAG_CATEGORY = gql`
  mutation DeleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      ... on Success {
        message
      }
      ... on ErrorDeleteCategory {
        message
      }
    }
  }
`;

export const DELETE_EMPLOYEE_RECORD_TAG_SUBCATEGORY = gql`
  mutation Mutation($input: DeleteSubcategoryInput!) {
    deleteSubcategory(input: $input) {
      ... on Success {
        message
      }
      ... on NewSubcategoryRequiredForDocuments {
        totalDocuments
      }
      ... on NewSubcategoryRequiredForRecordChecklist {
        recordChecklistNames
      }
      ... on ErrorDeleteSubcategory {
        message
      }
    }
  }
`;
