// @ts-check
import { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ControlledEmployeeRecordTagCategoryNameInput } from '../../../../../businessComponents/ControlledInputs/ControlledEmployeeRecordTagCategoryNameInput/ControlledEmployeeRecordTagCategoryNameInput';
import type { EmployeeRecordTagCategoryUpdateMiniFormProps } from './EmployeeRecordTagCategoryUpdateMiniForm.types';
import { DEFAULT_CATEGORY_UPDATE_FORM } from '../EmployeeRecordTagsCatalog.constants';
import { CategoryNameInputStyles } from './EmployeeRecordTagCategoryUpdateMiniForm.styles';
import { useEmployeeRecordTagUpdate } from '../EmployeeRecordTagsCatalogHooks';
import {
  useBlockableActions,
  SORA_MODULES,
} from '../../../../../components/ModuleBlockableComponent';
import type { ModulesAvailable } from '../../../../../components/ModuleBlockableComponent/useBlockableActions';

export const EmployeeRecordTagCategoryUpdateMiniForm = (
  props: EmployeeRecordTagCategoryUpdateMiniFormProps,
) => {
  const { category, categoryIndex, disabled = false } = props;
  const { handleUpdateEmployeeRecordTagCategory } =
    useEmployeeRecordTagUpdate();

  const {
    isModuleAvailable: isDocumentsModuleAvailable,
    onClick: documentsModuleBlockedOnClick,
  } = useBlockableActions(SORA_MODULES.DOCUMENTS as ModulesAvailable);

  const form = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_CATEGORY_UPDATE_FORM,
  });

  const formIsValid = form.formState.isValid;
  const formIsDirty = form.formState.isDirty;

  const handleUpdate = () => {
    if (!isDocumentsModuleAvailable) {
      form.reset(category);
      return documentsModuleBlockedOnClick();
    }
    if (categoryIndex === undefined || categoryIndex === -1) return;
    if (!formIsValid || !formIsDirty) return;
    handleUpdateEmployeeRecordTagCategory({
      category: form.getValues(),
      newIndex: categoryIndex,
    });
  };

  useEffect(() => {
    form.reset(category);
  }, [category, form]);

  return (
    <Stack component="form" onSubmit={form.handleSubmit(handleUpdate)}>
      <ControlledEmployeeRecordTagCategoryNameInput
        control={form.control}
        textFieldProps={{
          label: '',
          size: 'small',
          disabled,
          onKeyDown: (e) => {
            if (e.code === 'Escape') {
              form.reset(category);
              e.currentTarget.blur();
            }
          },
          onFocus: (e) => {
            e.currentTarget.select();
          },
          onBlur: handleUpdate,
          sx: CategoryNameInputStyles,
        }}
      />
    </Stack>
  );
};
